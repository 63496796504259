import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import './FakeBreadcrumbs.style';
/** @namespace Pwa/Component/FakeBreadcrumbs/Component */
export class FakeBreadcrumbsComponent extends PureComponent {
    static propTypes = {
        productName: PropTypes.string.isRequired,
    };

    render() {
        const { productName } = this.props;

        if (!productName) {
            return (
                <div block="FakeBreadcrumbs">
                    <TextPlaceholder block="FakeBreadcrumbs" elem="Placeholder" />
                </div>
            );
        }

        return (
            <div block="FakeBreadcrumbs">
                <ul block="FakeBreadcrumbs" elem="List">
                    <li block="FakeBreadcrumbs" elem="ListItem">
                        <Link to="/">Home</Link>
                    </li>
                    <li block="FakeBreadcrumbs" elem="ListItem">&gt;</li>
                    <li block="FakeBreadcrumbs" elem="ListItem">
                        <span>{ productName }</span>
                    </li>
                </ul>
            </div>
        );
    }
}

export default FakeBreadcrumbsComponent;
