import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ProductSlider from 'Component/ProductSlider';
import { ProductType } from 'Type/ProductList.type';

import './ProductVariantsSlider.style';

/** @namespace Pwa/Component/ProductVariantsSlider/Component */
export class ProductVariantsSliderComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        title: PropTypes.string.isRequired,
    };

    render() {
        const { product: { mc_product_variants = [] }, title } = this.props;

        mc_product_variants.sort((a, b) => {
            const sortingA = a.varianttag_sorting !== undefined ? a.varianttag_sorting : Number.MAX_SAFE_INTEGER;
            const sortingB = b.varianttag_sorting !== undefined ? b.varianttag_sorting : Number.MAX_SAFE_INTEGER;

            return sortingA - sortingB;
        });

        const config = {
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 3,
                },
            },
            theme: 'Related',
            stickers: 'third_level',
        };

        return (mc_product_variants.length > 0) && (
            <ContentWrapper
              mix={ { block: 'ProductVariantsSlider' } }
              wrapperMix={ { block: 'ProductVariantsSlider', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                    <p block="ProductVariantsSlider" elem="Title">{ title }</p>
                    <ProductSlider
                      items={ mc_product_variants }
                      config={ config }
                      isVariants
                      list="third level products variants"
                    />
            </ContentWrapper>
        );
    }
}

export default ProductVariantsSliderComponent;
