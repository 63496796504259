import {
    CarouselScrollArrowComponent as SourceCarouselScrollArrow,
} from 'SourceComponent/CarouselScrollArrow/CarouselScrollArrow.component';

import './CarouselScrollArrow.override.style';

/** @namespace Pwa/Component/CarouselScrollArrow/Component */
export class CarouselScrollArrowComponent extends SourceCarouselScrollArrow {
    // TODO implement logic
}

export default CarouselScrollArrowComponent;
