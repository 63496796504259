import { withRouter } from 'react-router';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import CarouselScroll from 'Component/CarouselScroll';
import IfStore from 'Component/IfStore';
import Image from 'Component/Image';
import ImageSticker from 'Component/ImageSticker';
import McVideoPlayer from 'Component/McVideoPlayer';
import { MediaType } from 'Component/ProductGallery/ProductGallery.config';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';
import Slider from 'Component/Slider';
import VideoPopup from 'Component/VideoPopup';
import { ProductGalleryComponent as SourceProductGallery } from 'SourceComponent/ProductGallery/ProductGallery.component';

import './ProductGallery.override.style';

/** @namespace Pwa/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGallery {
    // static propTypes = {
    //     gallery: PropTypes.arrayOf(
    //         PropTypes.shape({
    //             id: PropTypes.oneOfType([
    //                 PropTypes.number,
    //                 PropTypes.string
    //             ]),
    //             image: PropTypes.string,
    //             isPlaceholder: PropTypes.bool,
    //             alt: PropTypes.string,
    //             type: PropTypes.string,
    //             media_type: PropTypes.string
    //         })
    //     ).isRequired,
    //     productId: PropTypes.number,
    //     product: ProductType.isRequired,
    //     isZoomEnabled: PropTypes.bool.isRequired,
    //     activeImage: PropTypes.number.isRequired,
    //     onActiveImageChange: PropTypes.func.isRequired,
    //     handleZoomChange: PropTypes.func.isRequired,
    //     registerSharedElementDestination: PropTypes.func.isRequired,
    //     disableZoom: PropTypes.func.isRequired,
    //     location: LocationType.isRequired,
    //     sliderRef: PropTypes.object.isRequired,
    //     handleImageZoomPopupActiveChange: PropTypes.func.isRequired,
    //     isMobile: PropTypes.bool.isRequired,
    //     isImageZoomPopupActive: PropTypes.bool.isRequired
    // };

    static defaultProps = {
        isImageZoomPopupActive: false,
        isZoomEnabled: false,

    };

    renderAdditionalPictures() {
        const {
            gallery,
            isImageZoomPopupActive,
            activeImage,
            onActiveImageChange,
            nuovo_template_option,
        } = this.props;

        if (gallery.length === 1) {
            return <div block="ProductGallery" elem="Additional" />;
        }

        // eslint-disable-next-line fp/no-let
        let gallerySlider;

        if ((!!nuovo_template_option?.label) && (nuovo_template_option?.label !== '') && (nuovo_template_option?.label !== 'template_camere')) {
            gallerySlider = gallery.filter((media) => media.media_type !== 'external-video');
        } else {
            gallerySlider = gallery;
        }

        if (nuovo_template_option?.label === 'template_materassi') {
            gallerySlider = gallerySlider.filter((media) => !media.types.includes('placeholder_video'));
        }

        return (
            <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive } }>
                <CarouselScroll
                  activeItemId={ activeImage }
                  onChange={ onActiveImageChange }
                  showedItemCount={ 4 }
                  showedActiveItemNr={ 1 }
                >
                    { gallerySlider.map(this.renderAdditionalPicture.bind(this)) }
                </CarouselScroll>
            </div>
        );
    }

    renderCTAConfigurator() {
        const { product: { name, sku }, cta } = this.props;

        const url = cta && cta !== '' ? `${__('/configuratore-cucine')}?${cta}` : `${__('/configuratore-cucine')}`;

        return (
            <a
              href={ url }
              className="configuratoreCucine"
              onClick={ () => {
                  window?.dataLayer?.push({
                      event: 'kitchen_configurator',
                      category: 'kitchen_configurator',
                      action: 'start',
                      start_point: 'product_page',
                      product_name: name,
                      category_name: 'cucine',
                      product_id: sku,
                  });
              } }
            >
                { __('CONFIGURA E ACQUISTA2') }
                { ' ' }
                &nbsp;
                <i className="fa fa-caret-right" aria-hidden="true" />
            </a>
        );
    }

    renderSlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            warranty,
            nuovo_template_option,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        let newGallery = gallery;

        if (nuovo_template_option?.label === 'template_materassi') {
            newGallery = gallery.filter((media) => !media.types.includes('placeholder_video'));
        }

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCrumbs
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { newGallery.map(this.renderSlide) }
                </Slider>
                { warranty && warranty.map((tag) => (
                    <ImageSticker key={ tag.position } tag={ tag } />
                )) }
                { this.renderDiscountTag() }
            </div>
        );
    }

    renderSlide(media, index) {
        const { media_type } = media;
        const { nuovo_template_option } = this.props;

        switch (media_type) {
        case MediaType.IMAGE:
            return this.renderImage(media, index);
        case MediaType.VIDEO:
            return (((!nuovo_template_option?.label) || (nuovo_template_option?.label === 'false') || (nuovo_template_option?.label === 'template_camere')) && (
                    <div block="ProductGallery" elem="VideoBackupImage">
                        <div block="ProductGallery" elem="TryVideo">
                            { this.renderVideo(media, index) }
                        </div>
                        <div block="ProductGallery" elem="BackupImage">
                            { this.renderImage(media, index) }
                        </div>
                    </div>
            ));
        case MediaType.PLACEHOLDER:
            return this.renderPlaceholder(index);
        default:
            return null;
        }
    }

    renderDiscountTag() {
        const {
            product: {
                mc_flashsale: { discount_image = {} } = {},
                price_range: { miglior_prezzo = null } = {},
            } = {},
            isMobile,
        } = this.props;

        const { standard, xs } = discount_image || {};
        const image = (isMobile) ? xs : standard;

        if (miglior_prezzo === '1') {
            return null;
        }

        return image && (
            <>
                <IfStore storeCode="default">
                    <div block="ImageSticker" mods={ { Position: 'TOPRIGHT', size: 'small' } } className="italySticker">
                        <img
                          block="ImageSticker"
                          elem="CustomDiscount"
                          src={ `${image}` }
                          alt="Promo"
                          className="italySticker"
                          style={ { width: miglior_prezzo === '0' ? '100%' : '', height: miglior_prezzo === '0' ? 'auto' : '' } }
                        />
                    </div>
                </IfStore>
                <IfStore storeCode="spain">
                    <div block="ImageSticker" mods={ { Position: 'TOPRIGHT', size: 'small' } }>
                        <img
                          block="ImageSticker"
                          elem="CustomDiscount"
                          src={ `${image}` }
                          alt="Promo"
                        />
                    </div>
                </IfStore>
            </>
        );
    }

    renderMcDescrizione2() {
        const { product: { mc_inline_description2 } } = this.props;

        return mc_inline_description2 && (
            <section
              block="ProductGallery"
              elem="Section"
              mods={ { type: 'mcInlineDescription2' } }
            >
                <span>
                    { mc_inline_description2 }
                </span>
            </section>
        );
    }

    renderMcVideo() {
        const {
            gallery,
        } = this.props;

        const [media] = gallery.filter((image) => image.media_type === 'external-video');
        const index = 'prova';

        return media && this.renderVideo(media, index);
    }

    renderVideo(media, index) {
        return (
            <McVideoPlayer
              key={ index }
              media={ media }
            />
        );
    }

    // Control() {
    //     const { zoomIn, zoomOut, resetTransform } = useControls();
    //     return (
    //         <div className="tools">
    //             <button onClick={() => zoomIn()}>+</button>
    //             <button onClick={() => zoomOut()}>-</button>
    //             <button onClick={() => resetTransform()}>x</button>
    //         </div>
    //     );
    // }

    renderImage(mediaData, index) {
        const {
            isZoomEnabled,
            handleZoomChange,
            disableZoom,
            isMobile,
            isImageZoomPopupActive,
            handleImageZoomPopupActiveChange,
        } = this.props;

        if (!isMobile) {
            const {
                large: { url: largeSrc } = {},
            } = mediaData;

            const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            const src = largeSrc;

            return (
                <TransformWrapper
                  wheel={ { disabled: true } }
                    // Reset zoom su double click
                  doubleClick={ { mode: 'reset' } }
                    // velocityAnimation = {{disabled : true}}
                    // pan = {{paddingSize: 0}}
                    // zoomAnimation = {{ disabled :true}}
                    // alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                >

                    { ({ zoomIn }) => (
                        <button
                          onClick={ isImageZoomPopupActive ? zoomIn : () => {
                              if (!isImageZoomPopupActive) {
                                  handleImageZoomPopupActiveChange(true);
                              } else {
                                  zoomIn();
                              }
                          } }
                        >
                            <TransformComponent>
                                <Image
                                  key={ index }
                                  src={ src }
                                  ratio="custom"
                                  mix={ {
                                      block: 'ProductGallery',
                                      elem: 'SliderImage',
                                      mods: { isPlaceholder: !src },
                                  } }
                                  isPlaceholder={ !src }
                                  style={ style }
                                />
                            </TransformComponent>
                        </button>
                    ) }
                </TransformWrapper>
            );
        }

        return (
            <TransformWrapper
              key={ index }
              onZoomChange={ handleZoomChange }
              onWheelStart={ this.onWheelStart }
              onWheel={ this.onWheel }
              wheel={ { disabled: true } }
                //   doubleClick={ { mode: 'reset' } }
              pan={ {
                  disabled: !isZoomEnabled,
                  limitToWrapperBounds: true,
                  velocity: false,
              } }
              options={ {
                  limitToBounds: true,
                  minScale: 1,
              } }
            >
                { ({
                    scale,
                    previousScale,
                    resetTransform,
                    setTransform,
                }) => {
                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                          setTransform={ setTransform }
                          index={ index }
                          mediaData={ mediaData }
                          scale={ scale }
                          previousScale={ previousScale }
                          disableZoom={ disableZoom }
                          isZoomEnabled={ isZoomEnabled }
                        />
                    );
                } }
            </TransformWrapper>
        );
    }

    render() {
        const { nuovo_template_option, buttonConfigurator, flagConfigurator } = this.props;

        return (
            <div block="ProductGallery">
                { this.renderAdditionalPictures() }
                { this.renderSlider() }
                { ((!nuovo_template_option?.label) || (nuovo_template_option?.label === 'false')) && (
                    this.renderMcDescrizione2()
                ) }
                { buttonConfigurator && (flagConfigurator === '1') && (
                    <IfStore storeCode="default">
                        { this.renderCTAConfigurator() }
                    </IfStore>
                ) }
                <VideoPopup />
            </div>
        );
    }
}

export default withRouter(ProductGalleryComponent);
