/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductType } from 'Type/ProductList.type';

import ProductVariantsSlider from './ProductVariantsSlider.component';

export const listName = 'third level products variants';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/ProductVariantsSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    product: state.ProductReducer.product,
});

/** @namespace Pwa/Component/ProductVariantsSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    pushProductImpressions: (products, list) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushProductImpressions(dispatch, products, list)
    ),
});

/** @namespace Pwa/Component/ProductVariantsSlider/Container */
export class ProductVariantsSliderContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        title: PropTypes.string.isRequired,
        pushProductImpressions: PropTypes.func.isRequired,
    };

    containerFunctions = {

    };

    componentDidMount() {
        const { product: { mc_product_variants }, pushProductImpressions } = this.props;

        if (mc_product_variants.length > 0) {
            pushProductImpressions(mc_product_variants, listName);
        }
    }

    componentDidUpdate(prevProps) {
        const { product: { mc_product_variants: newVariants }, pushProductImpressions } = this.props;
        const { product: { mc_product_variants: oldVariants } } = prevProps;

        const { sku: oldFirstSku } = oldVariants[0] || {};
        const { sku: newFirstSku } = newVariants[0] || {};

        if (newFirstSku !== oldFirstSku) {
            pushProductImpressions(newVariants, listName);
        }
    }

    containerProps = () => {
        const { product, title } = this.props;

        return {
            product,
            title,
        };
    };

    render() {
        return (
            <ProductVariantsSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantsSliderContainer);
