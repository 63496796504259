import PropTypes from 'prop-types';
import { lazy, PureComponent } from 'react';
import { connect } from 'react-redux';

import { McTechSpecsType } from 'Type/McTechSpecs';
import { ProductType } from 'Type/ProductList.type';

import './InformazioniAggiuntive.style';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const TEMPLATES = {
    letti: lazy(() => import('./templates/letti.component.js')),
    armadi: lazy(() => import('./templates/armadi.component.js')),
    como_e_comodini: lazy(() => import('./templates/comoecomodini.component.js')),
    elettrodomestici: lazy(() => import('./templates/elettrodomestici.component.js')),
    cucine: lazy(() => import('./templates/cucine.component.js')),
    camere: lazy(() => import('./templates/camere.component.js')),
    sedie: lazy(() => import('./templates/sedie.component.js')),
    divani: lazy(() => import('./templates/divani.component.js')),
    soggiorni: lazy(() => import('./templates/soggiorni.component.js')),
    camerette: lazy(() => import('./templates/camerette.component.js')),
    default: lazy(() => import('./templates/default.component.js')),
    empty: lazy(() => import('./templates/empty.component.js')),
    tavoli: lazy(() => import('./templates/tavoli.component.js')),
    bagni: lazy(() => import('./templates/bagni.component.js')),
    reti: lazy(() => import('./templates/reti.component.js')),
    materassi: lazy(() => import('./templates/materassi.component.js')),
};

export const McTechSpecsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/McTechSpecs/McTechSpecs.dispatcher'
);

/** @namespace Pwa/Component/InformazioniAggiuntive/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mcTechSpecs: state.McTechSpecsReducer.mcTechSpecs,
});

/** @namespace Pwa/Component/InformazioniAggiuntive/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getMcTechSpecs: (sku) => McTechSpecsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, sku)
    ),
});

/** @namespace Pwa/Component/InformazioniAggiuntive/Container */
export class InformazioniAggiuntiveContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        getMcTechSpecs: PropTypes.func.isRequired,
        sku: PropTypes.string,
        mcTechSpecs: McTechSpecsType.isRequired,
    };

    static defaultProps = {
        sku: undefined,
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    componentDidMount() {
        const { getMcTechSpecs, product: { sku } } = this.props;

        if (sku) {
            getMcTechSpecs(sku);
        }
    }

    componentDidUpdate(prevProps) {
        const { product: { sku }, getMcTechSpecs } = this.props;
        const { product: { sku: prevSku } } = prevProps;

        if (sku && sku !== prevSku) {
            getMcTechSpecs(sku);
        }
    }

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    render() {
        const { mcTechSpecs, product: { mc_techspecs_template = 'empty' } } = this.props;
        const TechSpecTemplateComponent = TEMPLATES[mc_techspecs_template];

        return <TechSpecTemplateComponent mcTechSpecs={ mcTechSpecs } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InformazioniAggiuntiveContainer);
