import { connect } from 'react-redux';

import {
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import { showPopup } from 'Store/Popup/Popup.action';

import ProductFixedBoxComponent from './ProductFixedBox.component';

/** @namespace Pwa/Component/ProductFixedBox/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    product: state.ProductReducer.product,
    device: state.ConfigReducer.device,
    thirdLevelQTY: state.AngoloDelleOccasioniReducer.thirdLevelQTY,
});

/** @namespace Pwa/Component/ProductFixedBox/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
});

/** @namespace Pwa/Component/ProductFixedBox/Container */
export class ProductFixedBoxContainer extends SourceProductContainer {
    containerProps() {
        return {
            ...super.containerProps(),
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        showPopup: this.props.showPopup,
    };

    render() {
        return (
            <ProductFixedBoxComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFixedBoxContainer);
