import { Field } from '@tilework/opus';

/** @namespace Pwa/Query/WarehouseSearch/Query */
export class WarehouseSearchQuery {
    getQuery(text) {
        const query = new Field('mc_search_warehouse')
            .addField('district')
            .addField('province')
            .addField('zipcode')
            .addField('code');

        query.addArgument('text', 'String!', text);

        return query;
    }
}

export default new WarehouseSearchQuery();
