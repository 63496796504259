import {
    VideoThumbnailComponent as SourceVideoThumbnail,
} from 'SourceComponent/VideoThumbnail/VideoThumbnail.component';

import './VideoThumbnail.override.style';

/** @namespace Pwa/Component/VideoThumbnail/Component */
export class VideoThumbnailComponent extends SourceVideoThumbnail {
    render() {
        const {
            media: {
                video_content: { video_title },
            },
            onPlayClick,
        } = this.props;

        return (
            <div block="VideoThumbnail">
                <button
                  block="VideoThumbnail"
                  elem="Button"
                  onClick={ onPlayClick }
                  title={ __('Play video %s', video_title) }
                >
                    <span className="videoStart">
                        <i className="fa fa-caret-right" />
                    </span>
                    <span className="videoText">
                        { __('Watch the video') }
                    </span>
                </button>
            </div>
        );
    }
}

export default VideoThumbnailComponent;
