import {
    VideoPopupComponent as SourceVideoPopup,
} from 'SourceComponent/VideoPopup/VideoPopup.component';

import './VideoPopup.override.style';

/** @namespace Pwa/Component/VideoPopup/Component */
export class VideoPopupComponent extends SourceVideoPopup {
    // TODO implement logic
}

export default VideoPopupComponent;
