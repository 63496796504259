import { PureComponent } from 'react';

import { MediaItemType } from 'Type/ProductList.type';
import { makeCancelable } from 'Util/Promise';

import { YOUTUBE_FORMAT } from './McVideoPlayer.config';

import './McVideoPlayer.style';

/** @namespace Pwa/Component/McVideoPlayer/Component */
export class McVideoPlayerComponent extends PureComponent {
    static propTypes = {
        media: MediaItemType.isRequired,
    };

    state = {
        youTubeComponent: null,
    };

    componentDidMount() {
        this.loadYouTubeLibrary();

        Promise.all([
            this.youTubePromise,
        ]).then(
            /** @namespace Pwa/Component/McVideoPlayer/Component/McVideoPlayerComponent/componentDidMount/all/then */
            () => this.forceUpdate()
        );
    }

    componentWillUnmount() {
        if (this.youTubePromise) {
            this.youTubePromise.cancel();
        }
    }

    _renderYoutubeVideo(videoId) {
        const { youTubeComponent: YouTube } = this.state;

        if (!YouTube) {
            return null;
        }

        return (
            <YouTube
              videoId={ videoId }
              containerClassName="VideoPopup-YouTubeContainer"
              className="VideoPopup-YouTube"
              opts={ {
                  playerVars: {
                      autoplay: 0,
                      controls: 0,
                      rel: 0,
                      version: 3,
                      loop: 1,
                      playlist: videoId,
                  },
              } }
            />
        );
    }

    loadYouTubeLibrary() {
        this.youTubePromise = makeCancelable(import('react-youtube'));

        this.youTubePromise.promise.then(
            /** @namespace Pwa/Component/McVideoPlayer/Component/McVideoPlayerComponent/loadYouTubeLibrary/then */
            ({ default: youTube }) => {
                this.setState({ youTubeComponent: youTube });
            }
        );
    }

    _renderVideoContent() {
        const {
            media: {
                video_content: { video_url } = {},
            } = {},
        } = this.props;

        if (!video_url) {
            return null;
        }

        const [, youtubeId] = YOUTUBE_FORMAT.exec(video_url);

        if (youtubeId) {
            return this._renderYoutubeVideo(youtubeId);
        }

        return null;
    }

    render() {
        return (
            <div block="McVideoPlayer">
                { this._renderVideoContent() }
            </div>
        );
    }
}

export default McVideoPlayerComponent;
