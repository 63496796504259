import PropTypes from 'prop-types';

export const McTechSpecsType = PropTypes.shape({
    description: PropTypes.string,
    mc_features: PropTypes.string,
    mc_to_mount: PropTypes.bool,
    mc_dimension_width: PropTypes.string,
    mc_dimension_depth: PropTypes.string,
    mc_dimension_height: PropTypes.string,
    mc_measure_image: PropTypes.string,
    mc_box_count: PropTypes.string,
    mc_maintenance: PropTypes.string,
    mca_l_contenitore: PropTypes.string,
    mca_p_contenitore: PropTypes.string,
    mca_h_contenitore: PropTypes.string,
    mca_l_letto_utile: PropTypes.string,
    mca_p_letto_utile: PropTypes.string,
    mca_l_pediera: PropTypes.string,
    mca_h_pediera: PropTypes.string,
    mca_l_testiera: PropTypes.string,
    mca_h_testiera: PropTypes.string,
    mca_l_rete: PropTypes.string,
    mca_h_rete: PropTypes.string,
    mca_p_rete: PropTypes.string,
    mca_n_vani: PropTypes.string,
    mca_tip_str: PropTypes.string,
    mca_letto_tip: PropTypes.string,
    mca_letto_mat: PropTypes.string,
    mc_box_sizes: PropTypes.arrayOf(
        PropTypes.shape({
            w: PropTypes.string,
            h: PropTypes.string,
            p: PropTypes.string,
        })
    ),
    mc_pdf_manuals: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        })
    ),
});
