/* eslint-disable react/prop-types */
import parse from 'html-react-parser';
import { PureComponent } from 'react';

import './MaterassoInfo.style';

/** @namespace Pwa/Component/MaterassoInfo/Component */
export class MaterassoInfoComponent extends PureComponent {
    getMaterassiInfo() {
        const {
            product: {
                mc_info_materasso,
            },
        } = this.props;

        const basePath = 'media/catalog/materassi';
        const ent = [];

        if (mc_info_materasso.mc_matt_anallergico) {
            ent.push({ image: `${basePath}/anallergico.jpg`, text: mc_info_materasso.mc_matt_anallergico_label });
        }

        if (mc_info_materasso.mc_matt_antiacaro) {
            ent.push({ image: `${basePath}/antiacaro.jpg`, text: mc_info_materasso.mc_matt_antiacaro_label });
        }

        if (mc_info_materasso.mc_matt_ecologico) {
            ent.push({ image: `${basePath}/ecologico.jpg`, text: mc_info_materasso.mc_matt_ecologico_label });
        }

        if (mc_info_materasso.mc_matt_imbottitura) {
            ent.push({ image: `${basePath}/imbottitura.jpg`, text: mc_info_materasso.mc_matt_imbottitura_label });
        }

        if (mc_info_materasso.mc_matt_l_estivo) {
            ent.push({ image: `${basePath}/estivo.jpg`, text: mc_info_materasso.mc_matt_l_estivo_label });
        }

        if (mc_info_materasso.mc_matt_l_invernale) {
            ent.push({ image: `${basePath}/invernale.jpg`, text: mc_info_materasso.mc_matt_l_invernale_label });
        }

        if (mc_info_materasso.mc_matt_m_bonnell) {
            ent.push({ image: `${basePath}/bonnell.jpg`, text: mc_info_materasso.mc_matt_m_bonnell_label });
        }

        if (mc_info_materasso.mc_matt_m_insacchettate) {
            ent.push({
                image: `${basePath}/insacchettate.jpg`,
                text: mc_info_materasso.mc_matt_m_insacchettate_label,
            });
        }

        if (mc_info_materasso.mc_matt_no_molle) {
            ent.push({ image: `${basePath}/no_molle.jpg`, text: mc_info_materasso.mc_matt_no_molle_label });
        }

        if (mc_info_materasso.mc_matt_riv_cotone) {
            ent.push({ image: `${basePath}/cotone.jpg`, text: mc_info_materasso.mc_matt_riv_cotone_label });
        }

        if (mc_info_materasso.mc_matt_riv_trasp) {
            ent.push({ image: `${basePath}/traspirante.jpg`, text: mc_info_materasso.mc_matt_riv_trasp_label });
        }

        if (mc_info_materasso.mc_matt_str_ortop) {
            ent.push({ image: `${basePath}/ortopedico.jpg`, text: mc_info_materasso.mc_matt_str_ortop_label });
        }

        return ent;
    }

    render() {
        const {
            imgGaranzia,
            product: {
                mc_info_materasso: {
                    att_materassi_img_icon,
                    att_materassi_txt_consigli,
                    att_materassi_img_right: link_img,
                },
            },
        } = this.props;

        const ent = this.getMaterassiInfo();

        return (
            <>
                <div className="MaterassoIcon">
                    { ent.map((item) => (
                            <div>
                                <img src={ item.image } alt="img" />
                                <span>{ item.text }</span>
                            </div>
                    )) }
                </div>
                <div className="ProductVideo-Wrapper MaterassoInfo">
                    <div>
                        <div>
                            { __('') }
                            <img src={ imgGaranzia } alt="renderBoxDx garanzia" />
                        </div>
                        <div className="box-consigli">
                            <img className="img-icon-materassi" src={ att_materassi_img_icon } alt="renderBoxDx icon" />
                            <div>
                                <p>{ parse(att_materassi_txt_consigli) }</p>
                            </div>
                        </div>
                    </div>
                    <img src={ link_img } alt="renderBoxDx" />
                </div>
            </>
        );
    }
}

export default MaterassoInfoComponent;
