import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    COLORS_POPUP_PREFIX,
    FIRST_COLOR_PARAM_NAME,
    MAX_PREVIEW_COLORS,
    MAX_PREVIEW_COLORS_TEMPLATE_ARMADI,
    MAX_PREVIEW_COLORS_TEMPLATE_BAGNI,
    MAX_PREVIEW_COLORS_TEMPLATE_CAMERE,
    MAX_PREVIEW_COLORS_TEMPLATE_CAMERETTE,
    MAX_PREVIEW_COLORS_TEMPLATE_CAMERETTE_BLOCCATE,
    MAX_PREVIEW_COLORS_TEMPLATE_CUCINE,
    MAX_PREVIEW_COLORS_TEMPLATE_CUCINE_BLOCCATE,
    MAX_PREVIEW_COLORS_TEMPLATE_CUCINE_V2,
    MAX_PREVIEW_COLORS_TEMPLATE_DIVANI,
    MAX_PREVIEW_COLORS_TEMPLATE_ELETTRODOMESTICI,
    MAX_PREVIEW_COLORS_TEMPLATE_LETTI,
    MAX_PREVIEW_COLORS_TEMPLATE_MATERASSI,
    MAX_PREVIEW_COLORS_TEMPLATE_RETI,
    MAX_PREVIEW_COLORS_TEMPLATE_SEDIE_ALTRO,
    MAX_PREVIEW_COLORS_TEMPLATE_SOGGIORNI_MADIE,
    MAX_PREVIEW_COLORS_TEMPLATE_TAVOLI,
} from 'Component/ColorSelect/ColorSelect.config';
import Popup from 'Component/Popup';
import { showPopup } from 'Store/Popup/Popup.action';
import { DeviceType } from 'Type/Device.type';
import { ProductType } from 'Type/ProductList.type';

import ColorSelect from './ColorSelect.component';

/** @namespace Pwa/Component/ColorSelect/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
    device: _state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ColorSelect/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showColorsPopup: (sku) => dispatch(showPopup(COLORS_POPUP_PREFIX + sku)),
});

/** @namespace Pwa/Component/ColorSelect/Container */
export class ColorSelectContainer extends PureComponent {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: String,
                label: String,
                image: String,
                url: String,
            })
        ).isRequired,
        product: ProductType.isRequired,
        value: PropTypes.string,
        maxImages: PropTypes.number,
        maxImagesArmadi: PropTypes.number,
        maxImagesBagni: PropTypes.number,
        maxImagesCucine: PropTypes.number,
        maxImagesCucineV2: PropTypes.number,
        maxImagesDivani: PropTypes.number,
        maxImagesLetti: PropTypes.number,
        maxImagesCamere: ProductType.number,
        maxImagesCamerette: PropTypes.number,
        maxImagesSoggiorniMadie: PropTypes.number,
        maxImagesSedieAltro: PropTypes.number,
        maxImagesCucineBloccate: PropTypes.number,
        maxImagesCameretteBloccate: PropTypes.number,
        maxImagesTavoli: PropTypes.number,
        maxImagesMaterassi: PropTypes.number,
        maxImagesReti: PropTypes.number,
        maxImagesElettrodomestici: PropTypes.number,
        showColorsPopup: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
    };

    static defaultProps = {
        value: null,
        maxImages: MAX_PREVIEW_COLORS,
        maxImagesArmadi: MAX_PREVIEW_COLORS_TEMPLATE_ARMADI,
        maxImagesBagni: MAX_PREVIEW_COLORS_TEMPLATE_BAGNI,
        maxImagesCucine: MAX_PREVIEW_COLORS_TEMPLATE_CUCINE,
        maxImagesDivani: MAX_PREVIEW_COLORS_TEMPLATE_DIVANI,
        maxImagesCucineV2: MAX_PREVIEW_COLORS_TEMPLATE_CUCINE_V2,
        maxImagesLetti: MAX_PREVIEW_COLORS_TEMPLATE_LETTI,
        maxImagesCamere: MAX_PREVIEW_COLORS_TEMPLATE_CAMERE,
        maxImagesCamerette: MAX_PREVIEW_COLORS_TEMPLATE_CAMERETTE,
        maxImagesSedieAltro: MAX_PREVIEW_COLORS_TEMPLATE_SEDIE_ALTRO,
        maxImagesSoggiorniMadie: MAX_PREVIEW_COLORS_TEMPLATE_SOGGIORNI_MADIE,
        maxImagesCucineBloccate: MAX_PREVIEW_COLORS_TEMPLATE_CUCINE_BLOCCATE,
        maxImagesCameretteBloccate: MAX_PREVIEW_COLORS_TEMPLATE_CAMERETTE_BLOCCATE,
        maxImagesTavoli: MAX_PREVIEW_COLORS_TEMPLATE_TAVOLI,
        maxImagesMaterassi: MAX_PREVIEW_COLORS_TEMPLATE_MATERASSI,
        maxImagesReti: MAX_PREVIEW_COLORS_TEMPLATE_RETI,
        maxImagesElettrodomestici: MAX_PREVIEW_COLORS_TEMPLATE_ELETTRODOMESTICI,
    };

    containerFunctions = {
        showColorsPopup: () => {
            const { showColorsPopup, product } = this.props;
            showColorsPopup(product.sku);
        },
    };

    _getOptions() {
        const { options } = this.props;

        return options;
    }

    _getValue() {
        const { value } = this.props;

        return value;
    }

    _getMaxImages() {
        const {
            product: {
                nuovo_template_option,
            },
            device,
            maxImages,
            maxImagesArmadi,
            maxImagesBagni,
            maxImagesCucine,
            maxImagesCucineBloccate,
            maxImagesDivani,
            maxImagesCucineV2,
            maxImagesLetti,
            maxImagesCamere,
            maxImagesCamerette,
            maxImagesSedieAltro,
            maxImagesSoggiorniMadie,
            maxImagesCameretteBloccate,
            maxImagesTavoli,
            maxImagesMaterassi,
            maxImagesReti,
            maxImagesElettrodomestici,
        } = this.props;

        // Se mi trovo in un nuovo_template_option e non mi trovo su mobile, setto il numero delle immagini corrispondenti
        switch (true) {
        case ((nuovo_template_option.label === 'template_cucine_componibili') && (!device.isMobile)):
            return maxImagesCucine;
        case (nuovo_template_option.label === 'template_cucine_componibiliV2'):
            return maxImagesCucineV2;
        case (nuovo_template_option.label === 'template_cucine_bloccate'):
            return maxImagesCucineBloccate;
        case ((nuovo_template_option.label === 'template_divani')):
        case ((nuovo_template_option.label === 'template_divani_2')):
            return maxImagesDivani;
        case ((nuovo_template_option.label === 'template_letti')):
            return maxImagesLetti;
        case ((nuovo_template_option.label === 'template_armadi'
            || nuovo_template_option.label === 'template_armadi2')):
            return maxImagesArmadi;
        case ((nuovo_template_option.label === 'template_camere')):
            return maxImagesCamere;
        case ((nuovo_template_option.label === 'template_camerette')):
            return maxImagesCamerette;
        case ((nuovo_template_option.label === 'template_bagni')):
            return maxImagesBagni;
        case ((nuovo_template_option.label === 'template_soggiorni_madie')):
            return maxImagesSoggiorniMadie;
        case ((nuovo_template_option.label === 'template_sedie_altro')):
            return maxImagesSedieAltro;
        case ((nuovo_template_option.label === 'template_camerette_bloccate')):
            return maxImagesCameretteBloccate;
        case ((nuovo_template_option.label === 'template_tavoli')):
            return maxImagesTavoli;
        case ((nuovo_template_option.label === 'template_materassi')):
            return maxImagesMaterassi;
        case ((nuovo_template_option.label === 'template_reti')):
            return maxImagesReti;
        case ((nuovo_template_option.label === 'template_elettrodomestici')):
            return maxImagesElettrodomestici;
        default:
            return maxImages;
        }
    }

    _getProduct() {
        const { product } = this.props;

        return product;
    }

    _getFirstColor() {
        const urlSearchParams = new URLSearchParams(location.search);

        return urlSearchParams.get(FIRST_COLOR_PARAM_NAME);
    }

    containerProps = () => ({
        options: this._getOptions(),
        value: this._getValue(),
        maxImages: this._getMaxImages(),
        product: this._getProduct(),
        firstColor: this._getFirstColor(),
    });

    popupContainerProps = () => ({
        options: this._getOptions(),
        value: this._getValue(),
        product: this._getProduct(),
        firstColor: this._getFirstColor(),
        isWideSelection: true,
    });

    render() {
        const { product: { name, sku, nuovo_template_option } } = this.props;

        return (
            <>
                <ColorSelect
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
                <Popup
                  isSidePopup={ (!nuovo_template_option.label) || (nuovo_template_option.label === 'false') }
                  isFullPopup={ (!!nuovo_template_option.label) && (nuovo_template_option.label !== 'false') }
                  id={ `${COLORS_POPUP_PREFIX}${sku}` }
                  clickOutside
                >
                    <h2 block="ColorSelect" elem="Title">
                      { __('Our proposals for ') }
                      { name }
                    </h2>
                    <ColorSelect
                      { ...this.popupContainerProps() }
                      { ...this.containerFunctions }
                    />
                </Popup>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorSelectContainer);
