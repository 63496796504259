import BlackFridayCounter from 'Component/BlackFridayCounter';
import CmsBlock from 'Component/CmsBlock';
import DimensionFormatter from 'Component/DimensionFormatter';
import FlashSalesIndicator from 'Component/FlashSalesIndicator';
import IfStore from 'Component/IfStore';
import McDimensioniPopup from 'Component/McDimensioniPopup';
import Popup from 'Component/Popup';
import {
    CATAS_CUSTOM_POPUP,
    COSMOB_CUSTOM_POPUP,
    METODO_CONSEGNA_CUSTOM_POPUP_NEW,
} from 'Component/ProductActions/ProductActions.config';
import ProductComposizioneTipo from 'Component/ProductCardDetails/Elements/ProductComposizioneTipo.component';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductActionsComponent as SourceProductActions,
} from 'SourceComponent/ProductActions/ProductActions.component';

import './ProductTopInfo.style';

/** @namespace Pwa/Component/ProductTopInfo/Component */
export class ProductTopInfoComponent extends SourceProductActions {
    // static propTypes = {
    //     product: ProductType.isRequired,
    //     showPopup: PropTypes.func.isRequired,
    //     device: DeviceType.isRequired
    // };

    renderNameAndBrand() {
        const { product: { name } } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'name' } }
            >
                <h1 block="ProductActions" elem="Title" itemProp="name">
                    <TextPlaceholder content={ name } length="medium" />
                </h1>
            </section>
        );
    }

    renderMcDescrizione() {
        const { product: { mc_inline_description } } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { isLoaded: !!mc_inline_description, type: 'mcInlineDescription' } }
            >
                <h2 className="mc_inline_description">
                    <TextPlaceholder content={ mc_inline_description } length="short" />
                </h2>
            </section>
        );
    }

    renderLogo() {
        const { product: { logo_elettrodomestico } } = this.props;

        return logo_elettrodomestico && (
            <div>
                <img className="img-elettrodomestico" src={ logo_elettrodomestico } alt="logo_elettrodomestico" />
            </div>
        );
    }

    renderMcColorInfo() {
        const { product: { mc_color_information } } = this.props;
        const { description } = mc_color_information || {};

        return mc_color_information && (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'mcColorInfo' } }
            >
                <h2 className="mc_color_info">
                    <TextPlaceholder content={ description } length="short" />
                </h2>
            </section>
        );
    }

    renderCertification() {
        const {
            product: { catas, cosmob }, showPopup,
        } = this.props;

        /**
         * cosmob derives from product attributes, isCosmob depends on cosmob AND display rules
         */
        const isCatas = Boolean(catas);
        const isCosmob = Boolean(cosmob);

        if (!(isCatas || isCosmob)) {
            return null;
        }
        const block_name = isCatas ? 'catas' : 'cosmob';
        const popup_name = isCatas ? CATAS_CUSTOM_POPUP : COSMOB_CUSTOM_POPUP;
        const certification_name = isCatas ? 'Catas' : 'Cosmob';
        const cms_block = `popup_${block_name}`;

        return (
            <>
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: certification_name } }
                >
                    <div
                      block="ProductActions"
                      elem={ certification_name }
                      role="button"
                      tabIndex="0"
                      onClick={ () => showPopup(popup_name) }
                      onKeyDown={ () => showPopup(popup_name) }
                    />
                </section>
                <Popup
                  isFullPopup
                  id={ popup_name }
                >
                    <CmsBlock identifier={ cms_block } />
                </Popup>
            </>
        );
    }

    renderCodice() {
        const { product: { sku } } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'CodiceSku' } }
            >
                <span>
                    { __('codice:') }
                    &nbsp;
                    { sku }
                </span>
            </section>
        );
    }

    getOfferType() {
        const { product: { variants } } = this.props;

        if (variants && variants.length >= 1) {
            return 'https://schema.org/AggregateOffer';
        }

        return 'https://schema.org/Offer';
    }

    getOfferCount() {
        const { product: { variants } } = this.props;

        if (variants && variants.length) {
            return variants.length;
        }

        return 0;
    }

    renderPrezzo() {
        const {
            product: {
                price_range: dataProd,
                categories,
            },
            device: { isMobile },
        } = this.props;

        const offerType = this.getOfferType();
        const offerCount = this.getOfferCount();

        return (
            <div
              block="ProductActions"
              elem="Schema"
              itemType={ offerType }
              itemProp="offers"
              itemScope
            >
                <div
                  block="ProductActions"
                  elem="PriceWrapper"
                >
                    { /* { this.renderConfigurablePriceBadge() } */ }
                    { this.renderSchema() }
                    <ProductPrice
                      promoPosition="top_left"
                      reverse={ isMobile }
                      isSchemaRequired
                      categories={ categories }
                      variantsCount={ offerCount }
                      price={ dataProd }
                      mix={ { block: 'ProductActions', elem: 'Price' } }
                    />
                </div>
            </div>
        );
    }

    renderMcComposizioneTipo() {
        const { product } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'mcComposizioneTipo' } }
            >
                <ProductComposizioneTipo product={ product } />
            </section>
        );
    }

    renderMcDimensioni() {
        const {
            product: {
                mc_dimension_height, mc_dimension_width, mc_dimension_depth,
                sku, mc_products_sizes,
            },
        } = this.props;

        if (mc_products_sizes) {
            const id_popup = `DIMENSIONI_PRODOTTO_${sku}`;

            return (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'mcDimensioni' } }
                >
                    <McDimensioniPopup mcProductSizes={ mc_products_sizes } id={ id_popup } />
                </section>

            );
        }
        const hasDimension = !!(mc_dimension_height && mc_dimension_width && mc_dimension_depth);

        return (
            hasDimension && (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'mcDimensioni' } }
                >
                    <h3>
                        <DimensionFormatter
                          height={ mc_dimension_height }
                          width={ mc_dimension_width }
                          depth={ mc_dimension_depth }
                        />
                    </h3>
                </section>
            )
        );
    }

    renderMcFlashSale() {
        const { product: { sku, mc_flashsale } } = this.props;

        return mc_flashsale && mc_flashsale.items_total > 0 && sku && (
            <div block="ProductActions" elem="FlashSale">
                <FlashSalesIndicator sku={ sku } flashsale={ mc_flashsale } isInfoIcon={ false } />
            </div>
        );
    }

    renderMcBlackFridayCounter() {
        const { product: { sku, mc_black_friday_counter } } = this.props;

        return mc_black_friday_counter && mc_black_friday_counter.items_total > 0 && sku && (
            <div block="ProductActions" elem="BlackFridayCounter">
                <BlackFridayCounter sku={ sku } blackFriday={ mc_black_friday_counter } isInfoIcon={ false } />
            </div>
        );
    }

    async componentDidMount() {
        const configData = await this.getapiUrl();
        this.setState({ configData });
    }

    async getapiUrl() {
        try {
            // Ottieni i dati dall'API
            const response = await fetch('/rest/V1/configurazioneRate/get/');
            const apiData = await response.json();

            return apiData;
        } catch (error) {
            return 'errore';
            // Gestisci l'errore secondo le tue esigenze
        }
    }

    renderRateFindomestic() {
        const { product, showPopup } = this.props;
        const { configData } = this.state || {};
        let prf = '';
        let tvei = '';

        if (configData) {
            prf = configData[0];
            tvei = configData[1];
        }
        const { mc_rata_findomestic_information: infoRata, price_range } = product;
        const logo = 'media/logo/logo-fin2.png';
        const valueForMultiplication = 100;
        const {
            maximum_price: {
                final_price: {
                    value: finalPrice,
                },
            },
        } = price_range;
        const minValueFinanziamento = 199;
        let linkToRedirectRata = `https://secure.findomestic.it/clienti/pmcrs/mondoconvenienza/mcommerce/pages/simulatore.html?versione=L&prf=${ prf }&tvei=${ tvei}`;

        if (finalPrice && finalPrice > minValueFinanziamento) {
            linkToRedirectRata = `${linkToRedirectRata}&Importo=${finalPrice * valueForMultiplication}`;
        }

        if (!infoRata) {
            return (
                <div>
                    <p className="rate">
                        { __('Payable in installments') }
                    </p>
                </div>
            );
        }

        return (infoRata && (infoRata.durata > 0 || infoRata.durata != null)) && (
            <>
                <IfStore storeCode="default">
                    <button
                      className="rate_findomestic_container"
                      onClick={ () => {
                          showPopup('FINAZIAMENTO_FINDOMESTIC');
                      } }
                    >
                        <p className="rate_findomestic">
                            { __('Payable in installments') }
                        </p>
                        <img src={ logo } alt="" />
                    </button>
                    <Popup
                      id="FINAZIAMENTO_FINDOMESTIC"
                    >
                        <div className="findomestic_popup">
                            <CmsBlock identifier="rate_findomestic_popup" />
                            <a
                              target="_blank"
                              className="Button AddToCart ProductActions-AddToCart"
                              href={ linkToRedirectRata }
                              rel="noreferrer"
                            >
                                { __('Calcola la tua rata') }
                            </a>
                        </div>
                    </Popup>
                </IfStore>
                <IfStore storeCode="spain">
                    <p className="rate">
                        { __('Payable in installments') }
                    </p>
                </IfStore>
            </>
        );
    }

    // specificRightTopInfo = () => {
    // const {
    //     product: {
    //         nuovo_template_option
    //     }
    // } = this.props;
    //
    // switch (nuovo_template_option.label) {
    // case 'template_camerette':
    //     return this.cameretteRightTopInfo();
    // default:
    //     return this.defaultRightTopInfo();
    // }
    // };

    // in caso della personalizzazione della responsivity
    // cameretteRightTopInfo = () => (
    //     <>
    //         { window.matchMedia('(min-width: 768px)').matches && (
    //             this.renderCertification()) }
    //         { window.matchMedia('(max-width: 1199px)').matches && (
    //             <div
    //               block="ProductActions"
    //               elem="NameAndPriceWrapper"
    //             >
    //                 <div block="ProductActions" elem="PrezzoMobile">
    //                     { this.renderPrezzo() }
    //                     { this.renderMcComposizioneTipo() }
    //                     { this.renderRateFindomestic() }
    //                     { this.renderCodice() }
    //                     { this.renderLogo() }
    //                 </div>
    //             </div>
    //         ) }
    //     </>
    // );

    defaultRightTopInfo = () => (
        <>
            { window.matchMedia('(min-width: 768px)').matches && (
                this.renderCertification()) }
            { window.matchMedia('(max-width: 767px)').matches && (
                <div
                  block="ProductActions"
                  elem="NameAndPriceWrapper"
                >
                    <div block="ProductActions" elem="PrezzoMobile">
                        { this.renderPrezzo() }
                        { this.renderMcComposizioneTipo() }
                        { this.renderRateFindomestic() }
                        { this.renderCodice() }
                        { this.renderLogo() }
                    </div>
                </div>
            ) }
        </>
    );

    renderSchedaTecnicaLink() {
        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'SchedaTecnicaLink' } }
            >
            <p className="schedaTecnicaAnchor" onClick={ () => this.scrollToTecnica() }>{ __('Scheda tecnica') }</p>

            </section>
        );
    }

    scrollToTecnica() {
        const element = document.getElementById('instructions'); // Ottiene l'elemento con ID 'instruction'

        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset; // Calcola la posizione dell'elemento
            // Effettua lo scroll con comportamento "smooth"
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth', // Scorrimento fluido
            });
        }
    }

    render() {
        const {
            product: {
                nuovo_template_option,
            },
            device,
            showPopup,
        } = this.props;

        return (
            <>
                { /* start nuovo componente inserito: */ }
                <div className="superior_content">
                    { this.renderMcBlackFridayCounter() }
                </div>
                { /* end nuovo componente inserito: */ }
                <div block="ProductTopInfo" className="d-flex inferior_content">
                    <div className="left_content">
                        { this.renderNameAndBrand() }
                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { (device.isMobile) && (
                            this.renderMcDimensioni()
                        ) }
                    { this.renderSchedaTecnicaLink() }

                        { (nuovo_template_option.label === 'template_cucine_bloccate') && (
                            <button className="consegna" onClick={ () => showPopup(METODO_CONSEGNA_CUSTOM_POPUP_NEW) }>Modalità di consegna</button>
                        ) }

                    </div>
                    <div className="right_content">
                        { this.defaultRightTopInfo() }
                    </div>
                    { (nuovo_template_option.label === 'template_divani') && window.matchMedia('(max-width: 811px)').matches && this.renderMcFlashSale() }
                    { (nuovo_template_option.label === 'template_divani_2') && window.matchMedia('(max-width: 811px)').matches && this.renderMcFlashSale() }
                </div>
            </>
        );
    }
}

export default ProductTopInfoComponent;
