import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
} from 'SourceComponent/Product/Product.container';
import {
    ProductActionsContainer as SourceProductActionsContainer,
} from 'SourceComponent/ProductActions/ProductActions.container';
import { showPopup } from 'Store/Popup/Popup.action';

import MaterassoInfo from './MaterassoInfo.component';

/** @namespace Pwa/Component/MaterassoInfo/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/MaterassoInfo/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
});

/** @namespace Pwa/Component/MaterassoInfo/Container */
export class MaterassoInfoContainer extends SourceProductActionsContainer {
    static defaultProps = {
        product: {},
    };

    containerProps = () => {
        const imgGaranzia = this.getAnniGaranzia();

        return {
            imgGaranzia,
        };
    };

    getAnniGaranzia() {
        const {
            product,
        } = this.props;

        if (Object.keys(product).length === 0) {
            return 0;
        }

        const {
            gar5anni, gar10anni, gar15anni, cosmob,
        } = product;

        if (gar5anni > 0) {
            if (cosmob > 0) {
                return 'media/catalog/garanzia/garanzia_5.png';
            }

            return 'media/catalog/garanzia/garanzia_5_nologo.png';
        }

        if (gar10anni > 0) {
            if (cosmob > 0) {
                return 'media/catalog/garanzia/garanzia_10.png';
            }

            return 'media/catalog/garanzia/garanzia_10_nologo.png';
        }

        if (gar15anni > 0) {
            if (cosmob > 0) {
                return 'media/catalog/garanzia/garanzia_15.png';
            }

            return 'media/catalog/garanzia/garanzia_15_nologo.png';
        }

        return 0;
    }

    render() {
        return (
            <MaterassoInfo
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterassoInfoContainer);
