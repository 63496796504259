import axios from 'axios';
import { connect } from 'react-redux';

import { ProductType } from 'Component/Product/Product.config';
import { MediaType } from 'Component/ProductGallery/ProductGallery.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer,
} from 'SourceComponent/ProductGallery/ProductGallery.container';
import { cacheImages } from 'Util/Cache/Cache';

/** @namespace Pwa/Component/ProductGallery/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    flagConfigurator: state.McMenuReducer.flagConfigurator,
});

/** @namespace Pwa/Component/ProductGallery/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
});

/** @namespace Pwa/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {
    state = {
        cta: '',
    };

    __construct(props) {
        super.__construct?.(props);

        this.handleImageZoomPopupClose = this.handleImageZoomPopupClose.bind(this);

        const { product: { id } = {} } = props;

        this.state = {
            activeImage: 0,
            isZoomEnabled: false,
            prevProdId: id,
            isImageZoomPopupActive: false,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            product: { media_gallery_entries: mediaGallery = [] },
            isZoomEnabled,
            areDetailsLoaded,
        } = this.props;
        const { product: { media_gallery_entries: prevMediaGallery = [] }, isZoomEnabled: prevZoomEnabled } = prevProps;

        if (mediaGallery !== prevMediaGallery || isZoomEnabled !== prevZoomEnabled) {
            // this.onActiveImageChange(this.getBaseImage());

            if (areDetailsLoaded && mediaGallery.length > 0) {
                this.cacheImages();
            }
        }
    }

    cacheImages() {
        const {
            product: {
                type_id: type,
                variants = [],
            },
        } = this.props;

        if (type !== ProductType.CONFIGURABLE) {
            return;
        }

        const urls = [];

        variants.forEach(({ media_gallery_entries: mediaGallery = [] }) => {
            if (mediaGallery.length > 0) {
                const { base: { url = '' } = {} } = mediaGallery[0];

                urls.push(url);
            }
        });

        cacheImages(urls);
    }

    getBaseImage(isInitial) {
        const {
            product: {
                media_gallery_entries: mediaGallery = [],
            },
        } = this.props;

        const baseImage = mediaGallery.filter((item) => !item.disabled).find((value) => value.types.includes(MediaType.IMAGE));
        const { position = 0 } = baseImage || {};

        if (isInitial && !mediaGallery.length) {
            return undefined;
        }

        if (!mediaGallery.length) {
            return 0;
        }

        const positionsArray = mediaGallery.filter((item) => !item.disabled).reduce((acc, item) => {
            const { position } = item;

            acc.push(position);

            return acc;
        }, []).sort((a, b) => a - b);

        const returnValue = positionsArray.findIndex((value) => value === position);

        if (returnValue === -1) {
            return 0;
        }

        return returnValue;
    }

    onActiveImageChange(activeImage) {
        this.setState({
            activeImage: activeImage !== undefined ? activeImage : activeImage,
            isZoomEnabled: false,
        });
    }

    handleImageZoomPopupActiveChange(isImageZoomPopupActive) {
        this.setState({ isImageZoomPopupActive });
    }

    componentDidMount() {
        this.getConfiguratorCTA();
        this.cacheImages();
    }

    getConfiguratorCTA = async () => {
        const { product: { sku } } = this.props;
        const STATUS_OK = 200;

        try {
            const response = await axios.get(`/rest/V1/configuratore/getCTA/${sku}`);

            if (response?.status === STATUS_OK) {
                this.setState({ cta: response.data });
            }
        } catch (error) {
            this.setState({ cta: '' });
        }
    };

    containerProps() {
        // CAPIATO ED ESTESO IL METODO DELLA CLASSE BASE
        // CERCARE UN METODO PER ESTENDERE QUELLO DELLA CLASSE BASE
        // ONUPDATE: DA CONTROLLARE IN CASO DI AGGIONAMENTO SCANDI
        const {
            cta,
        } = this.state;
        const {
            product, product: { mc_image_stickers: { third_level = [] } = {}, nuovo_template_option },
            buttonConfigurator, flagConfigurator,
        } = this.props;

        return {
            ...super.containerProps(),
            nuovo_template_option,
            buttonConfigurator,
            warranty: third_level,
            cta,
            product,
            flagConfigurator,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
