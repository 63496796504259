import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import FlixMediaConfigPropTypes from 'Component/FlixMedia/FlixMediaConfigPropTypes';

/** @namespace Pwa/Component/FlixMedia/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    flixMediaConfig: state.ConfigReducer.flixmedia,
    default_country: state.ConfigReducer.default_country,
});

/** @namespace Pwa/Component/FlixMedia/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/FlixMedia/Container */
export class FlixMediaContainer extends Component {
    static propTypes = {
        flixMediaConfig: FlixMediaConfigPropTypes.isRequired,
        ean: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        mpn: PropTypes.string,
        inpage: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
        default_country: PropTypes.string.isRequired,
        button_image: PropTypes.string,
        sku: PropTypes.string.isRequired,
        price: PropTypes.string,
    };

    static defaultProps = {
        button_image: '',
        mpn: '',
        price: '',
    };

    componentDidMount() {
        const { flixMediaConfig: { distributor_id } = {} } = this.props;

        if (distributor_id) {
            this.loadFlixScript();
        }
    }

    shouldComponentUpdate(nextProps) {
        const { ean, mpn } = this.props;
        const { ean: nextEan, mpn: nextMpn } = nextProps; /* update the component only if MPN or EAN value changes */

        return (ean !== nextEan || mpn !== nextMpn);
    }

    componentDidUpdate() {
        const { flixMediaConfig: { distributor_id } = {} } = this.props;

        if (distributor_id) {
            this.loadFlixScript();
        }
    }

    loadFlixScript() {
        const {
            flixMediaConfig: { distributor_id } = {},
            ean, inpage, button,
            brand, mpn, sku,
            button_image, price, default_country,
        } = this.props;

        this.cleanUp();
        const flixcontent = document.getElementById('FlixmediaContent');
        const flixScript = document.createElement('script');
        flixScript.async = true;
        flixScript.src = '//media.flixfacts.com/js/loader.js';
        flixScript.setAttribute('data-flix-distributor', distributor_id);
        flixScript.setAttribute('data-flix-language', default_country);
        flixScript.setAttribute('data-flix-ean', ean);
        flixScript.setAttribute('data-flix-inpage', inpage);
        flixScript.setAttribute('data-flix-button', button);
        flixScript.setAttribute('data-flix-fallback-language', default_country);
        flixScript.setAttribute('data-flix-brand', brand);
        flixScript.setAttribute('data-flix-mpn', mpn);
        flixScript.setAttribute('data-flix-sku', sku);
        flixScript.setAttribute('data-flix-button-image', button_image);
        flixScript.setAttribute('data-flix-price', price);
        flixcontent.innerHTML = '';
        this.instance.appendChild(flixScript);
    }

    cleanUp() {
        const flixResourceDomain = ['flixcar.com', 'flixfacts.com', 'flix360.com'];// remove flix related css/js
        const Flixscripts = document.getElementsByTagName('script');
        const Flixlinks = document.getElementsByTagName('link');
        for (let i = 0; i < Flixscripts.length; i++) {
            if (flixResourceDomain.some((flixDomain) => Flixscripts[i].src.includes(flixDomain))) {
                Flixscripts[i].parentNode.removeChild(Flixscripts[i]);
            }
        }
        for (let i = 0; i < Flixlinks.length; i++) {
            if (flixResourceDomain.some((flixDomain) => Flixlinks[i].href.includes(flixDomain))) {
                Flixlinks[i].parentNode.removeChild(Flixlinks[i]);
            }
        }
        // remove flixhotspot div
        const hotspotDiv = document.getElementById('flix_hotspots');

        if (hotspotDiv !== null) {
            hotspotDiv.remove();
        }
    }

    render() {
        return (
            // eslint-disable-next-line no-return-assign
            <div id="FlixmediaContent" ref={ (el) => (this.instance = el) } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlixMediaContainer);
