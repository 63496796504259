import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ProductSlider from 'Component/ProductSlider';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import { ProductType } from 'Type/ProductList.type';

import './LinkedProductsSlider.style';

/** @namespace Pwa/Component/LinkedProductsSlider/Component */
export class LinkedProductsSliderComponent extends PureComponent {
    static propTypes = {
        linkedProducts: PropTypes.objectOf(ProductType).isRequired,
        linkType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    };

    render() {
        const {
            title,
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = [],
                } = {},
            },
        } = this.props;
        const itemsNoAdo = items
            .filter((elem) => !elem.categories
                .find((el) => el.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI));

        const config = {
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                },
                1024: {
                    slidesPerView: 3.5,
                },
            },
            theme: 'Related',
            stickers: 'third_level',
        };

        return (itemsNoAdo.length > 0) && (
            <ContentWrapper
              mix={ { block: 'LinkedProductsSlider' } }
              wrapperMix={ { block: 'SliderVariants', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                <p block="LinkedProductsSlider" elem="Title">{ title }</p>
                <ProductSlider items={ itemsNoAdo } config={ config } list="related products" isVariants />
            </ContentWrapper>
        );
    }
}

export default LinkedProductsSliderComponent;
