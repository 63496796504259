/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import IfStore from 'Component/IfStore';
import Link from 'Component/Link';
import Popup from 'Component/Popup';
import {
    FINANCING_POPUP, GARANZIA_CUSTOM_POPUP,
    METODO_CONSEGNA_CUSTOM_POPUP,
    SERVIZI_CUSTOM_POPUP,
} from 'Component/ProductActions/ProductActions.config';
import { ProductType } from 'Type/ProductList.type';

import './ProductInfoAggiuntive.style';

/** @namespace Pwa/Component/ProductInfoAggiuntive/Component */
export class ProductInfoAggiuntiveComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        showPopup: PropTypes.func.isRequired,
        anniGaranzia: PropTypes.number.isRequired,
    };

    renderMetodoDiConsegna() {
        const {
            showPopup, product: {
                mc_national_carrier,
                mc_trasporto_montaggio,
                mc_venditacc_tablet,
                mc_ritiro_cliente,
            },
            anniGaranzia,
        } = this.props;

        const loaderPlaceholderSize = {
            width: '100%',
            height: '200px',
        };

        return (
            <>
                <div
                  block="ProductActions"
                  elem="InformazioniLink"
                  role="button"
                  tabIndex="0"
                  onClick={ () => {
                      this.fnDataPushPopup(METODO_CONSEGNA_CUSTOM_POPUP);
                      showPopup(METODO_CONSEGNA_CUSTOM_POPUP);
                  } }
                  onKeyDown={ () => showPopup(METODO_CONSEGNA_CUSTOM_POPUP) }
                >
                    <dt block="ProductActions" elem="MetodoDiConsegnaIcon" />
                    <dd
                      block="ProductActions"
                      elem="MetodoDiConsegnaText"
                      className={ !(anniGaranzia > 0) ? 'adapt_width' : '' }
                    >
                        { __('Delivery method') }
                        <p>{ __('You can have your order directly at your home or go and collect it.') }</p>

                    </dd>
                    { window.matchMedia('(max-width: 768px)').matches && (
                        <div className="customButtonProductService" />
                    ) }
                </div>

                <Popup
                  isFullPopup
                  id={ METODO_CONSEGNA_CUSTOM_POPUP }
                >
                    <div className="shipping-list box_full_popup">
                        <h1>{ __('Shipping Methods') }</h1>
                        <div className="shipping-list-flex">
                            { mc_trasporto_montaggio === 1 && (
                                <div className="shipping-list-flex-basis">
                                    <CmsBlock
                                      placeHolderSize={ loaderPlaceholderSize }
                                      identifier="popup_metodo_di_consegna_tm"
                                    />
                                </div>
                            ) }
                            { mc_national_carrier === 1 && (
                                <div className="shipping-list-flex-basis">
                                    <CmsBlock
                                      placeHolderSize={ loaderPlaceholderSize }
                                      identifier="popup_metodo_di_consegna_corriere"
                                    />
                                </div>
                            ) }
                            { mc_venditacc_tablet === 1 && (
                                <div className="shipping-list-flex-basis">
                                    <CmsBlock
                                      placeHolderSize={ loaderPlaceholderSize }
                                      identifier="popup_metodo_di_consegna_sottocasatua"
                                    />
                                </div>
                            ) }
                            { mc_ritiro_cliente === 1 && (
                                <div className="shipping-list-flex-basis">
                                    <CmsBlock
                                      placeHolderSize={ loaderPlaceholderSize }
                                      identifier="popup_metodo_di_consegna_ritiro"
                                    />
                                </div>
                            ) }
                        </div>
                    </div>
                </Popup>
            </>
        );
    }

    renderGaranzia() {
        const {
            showPopup, anniGaranzia,
        } = this.props;

        const cms_block = 'popup_anni_garanzia_';

        return (
            anniGaranzia > 0 && (
                <>
                    <div
                      block="ProductActions"
                      elem="InformazioniLink"
                      role="button"
                      tabIndex="0"
                      onClick={ () => showPopup(GARANZIA_CUSTOM_POPUP) }
                      onKeyDown={ () => showPopup(GARANZIA_CUSTOM_POPUP) }
                    >
                        <dt block="ProductActions" elem="GaranziaIcon" />
                        <dd block="ProductActions" elem="GaranziaText">
                            { anniGaranzia > 0 && __('%s year guarantee', anniGaranzia) }
                            <p>{ __('We protect the quality of your product') }</p>
                        </dd>
                        { window.matchMedia('(max-width: 768px)').matches && (
                            <div className="customButtonProductService" />
                        ) }
                    </div>
                    { anniGaranzia > 0 && (
                        <Popup
                          isFullPopup
                          id={ GARANZIA_CUSTOM_POPUP }
                        >
                            <div className="box_full_popup">
                                <CmsBlock
                                  identifier={ cms_block + anniGaranzia }
                                />
                            </div>
                        </Popup>
                    ) }
                </>
            )
        );
    }

    fnDataPushPopup(popupLbl) {
        let lbl = '';
        switch (popupLbl) {
        case FINANCING_POPUP:
            lbl = 'finanziamento';
            break;
        case METODO_CONSEGNA_CUSTOM_POPUP:
            lbl = 'metodi_di_spedizione';
            break;
        case SERVIZI_CUSTOM_POPUP:
            lbl = 'servizi';
            break;
        default:
            lbl = '';
        }

        window.dataLayer.push({
            event: 'click_tab',
            category: 'click',
            action: 'tab',
            label: lbl,
        });
    }

    renderFinanziamento() {
        const { anniGaranzia, showPopup } = this.props;
        const { product, configData } = this.props;
        let prf = '';
        let tvei = '';

        if (configData) {
            prf = configData[0];
            tvei = configData[1];
        }

        const { price_range } = product;
        const valueForMultiplication = 100;
        const {
            maximum_price: {
                final_price: {
                    value: finalPrice,
                },
            },
        } = price_range;
        const minValueFinanziamento = 199;
        let linkToRedirectRata = `https://secure.findomestic.it/clienti/pmcrs/mondoconvenienza/mcommerce/pages/simulatore.html?versione=L&prf=${prf}&tvei=${tvei}`;

        if (finalPrice && finalPrice > minValueFinanziamento) {
            linkToRedirectRata = `${linkToRedirectRata}&Importo=${finalPrice * valueForMultiplication}`;
        }

        return (
            <>
                <IfStore storeCode="spain">
                    <Link to="/modalita-pagamento/finanziamento" className="ProductActions-InformazioniLink">
                        <dt block="ProductActions" elem="FinanziamentoIcon" />
                        <dd
                          block="ProductActions"
                          elem="FinanziamentoText"
                          className={ !(anniGaranzia > 0) ? 'adapt_width' : '' }
                        >
                            { __('Financing') }
                            <p>{ __('Choose the installment tailored for you') }</p>
                        </dd>
                        { window.matchMedia('(max-width: 768px)').matches && (
                            <div className="customButtonProductService" />
                        ) }
                    </Link>
                </IfStore>
                <IfStore storeCode="default">
                    <div
                      role="button"
                      className="ProductActions-InformazioniLink"
                      onClick={ () => {
                          this.fnDataPushPopup(FINANCING_POPUP);
                          showPopup(FINANCING_POPUP);
                      } }
                    >
                        <dt block="ProductActions" elem="FinanziamentoIcon" />
                        <dd
                          block="ProductActions"
                          elem="FinanziamentoText"
                          className={ !(anniGaranzia > 0) ? 'adapt_width' : '' }
                        >
                            { __('Financing') }
                            <p>{ __('Choose the installment tailored for you') }</p>
                        </dd>
                        { window.matchMedia('(max-width: 768px)').matches && (
                            <div className="customButtonProductService" />
                        ) }
                    </div>
                    <Popup
                      id={ FINANCING_POPUP }
                      customClass="financingPopup"
                    >
                        <img src="https://media.mondoconv.it/media/wysiwyg/finanziamenti/logo-fin.png" alt="findomestic" />
                        <p className="title">Realizza i tuoi progetti con un finanziamento 100% online</p>
                        <p>Il finanziamento Findomestic è flessibile e facile da richiedere:</p>
                        <ul>
                            <li>
                                potrai scegliere il
                                <b>piano di rimborso più adatto a te</b>
                            </li>
                            <li>
                                tutti i costi sono
                                <b>inclusi</b>
                                { ' ' }
                                nella rata
                            </li>
                            <li>
                                pagherai la prima rata
                                <b>dopo 4 mesi</b>
                                { ' ' }
                                dall’acquisto
                            </li>
                        </ul>
                        <div className="ctas-container">
                            <a
                              target="_blank"
                              className="ProdutInfoAggiuntive-CTA"
                              href={ linkToRedirectRata }
                              rel="noreferrer"
                            >
                                { __('Calcola la tua rata') }
                            </a>
                            <a
                              target="_blank"
                              href="/modalita-pagamento/finanziamento"
                              rel="noreferrer"
                            >
                                Maggiori informazioni
                            </a>
                        </div>
                    </Popup>
                </IfStore>
            </>
        );
    }

    renderServizi() {
        const { showPopup, anniGaranzia } = this.props;
        const cms_block = 'popup_servizi';

        return (
            <>
                <div
                  block="ProductActions"
                  elem="InformazioniLink"
                  role="button"
                  tabIndex="0"
                  onClick={ () => {
                      this.fnDataPushPopup(SERVIZI_CUSTOM_POPUP);
                      showPopup(SERVIZI_CUSTOM_POPUP);
                  } }
                  onKeyDown={ () => showPopup(SERVIZI_CUSTOM_POPUP) }
                >
                    <dt block="ProductActions" elem="ServiziIcon" />
                    <dd
                      block="ProductActions"
                      elem="ServiziText"
                      className={ !(anniGaranzia > 0) ? 'adapt_width' : '' }
                    >
                        { __('Services') }
                        <p>{ __('Discover all the services at your disposal') }</p>
                    </dd>
                    { window.matchMedia('(max-width: 768px)').matches && (
                        <div className="customButtonProductService" />
                    ) }
                </div>
                <Popup
                  isFullPopup
                  id={ SERVIZI_CUSTOM_POPUP }
                >
                    <div className="box_full_popup">
                        <CmsBlock
                          identifier={ cms_block }
                        />
                    </div>
                </Popup>
            </>
        );
    }

    render() {
        return (
            <dl block="ProductActions" elem="ProductInfoAggiuntive">
                { this.renderMetodoDiConsegna() }
                { this.renderGaranzia() }
                { this.renderFinanziamento() }
                { this.renderServizi() }
            </dl>
        );
    }
}

export default ProductInfoAggiuntiveComponent;
