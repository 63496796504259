import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import AsyncSelect from 'react-select/async';

import './ProductCapBox.style';

/** @namespace Pwa/Component/ProductCapBox/Component */
export class ProductCapBoxComponent extends PureComponent {
    static propTypes = {
        loadOptions: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        title: PropTypes.string,
    };

    static defaultProps = {
        title: '',
    };

    renderTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h3 block="CartCoupon" elem="Title">
                { title }
            </h3>
        );
    }

    removeRequired = (e) => {
        e.currentTarget.classList.remove('required');
    };

    render() {
        const { loadOptions, onChange } = this.props;

        return (
            <div block="ProductCapBox" elem="Wrapper">
                <div block="ProductCapBox" elem="Title">{ __('Verifica le date di disponibilità') }</div>
                <div block="ProductCapBox" elem="Input" onClick={ this.removeRequired } onMouseDown={ this.removeRequired } onTouchEnd={ this.removeRequired }>
                    <AsyncSelect
                      onChange={ onChange }
                      noOptionsMessage={ () => __('CAP not found') }
                      loadingMessage={ () => __('searching ...') }
                      placeholder={ __('Inserisci la città o il CAP') }
                      cacheOptions
                      isSearchable
                      loadOptions={ loadOptions() }
                    />
                </div>
            </div>
        );
    }
}

export default ProductCapBoxComponent;
