import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';

import ProductPage from './ProductPage.component';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeCode: state.ConfigReducer.code,
    isLoadingADO: state.WarehouseReducer.isLoading,
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    pushProductDetails: (product) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushProductDetails(dispatch, product)
    ),
});

/** @namespace Pwa/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    static defaultProps = {
        productSKU: '',
        productID: 0,
        metaTitle: undefined,
    };

    containerFunctions = {
        ...this.containerFunctions,
    };

    containerProps() {
        return {
            ...super.containerProps(),
            ...this.props,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        const {
            pushProductDetails, product: { sku } = {}, productSKU,
        } = this.props;

        const dataSource = this.getDataSource();

        if (sku === productSKU) {
            pushProductDetails(dataSource);
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const { productSKU, pushProductDetails, product: { sku } = {} } = this.props;
        const { productSKU: prevProductSKU, product: { sku: prevSku } = {} } = prevProps;

        // FIX: MCT-1391 - Scroll bloccato a fine pagina
        if (productSKU !== prevProductSKU || (sku !== prevSku && sku === productSKU)) {
            pushProductDetails(this.getDataSource());

            // setTimeout(() => {
            //     this.scrollTop();
            //     // eslint-disable-next-line no-magic-numbers
            // }, 100);
        }
    }

    getProductRequestFilter() {
        const { productSKU } = this.props;
        const apiUrl = '/rest/V1/FineSerie/getFineSerieSkus/';

        // Esegui la richiesta Fetch
        return fetch(apiUrl)
            .then(
                /** @namespace Pwa/Route/ProductPage/Container/ProductPageContainer/getProductRequestFilter/then/catch/then/then/fetch/then */
                (response) => response.json()

            )
            .then(
                /** @namespace Pwa/Route/ProductPage/Container/ProductPageContainer/getProductRequestFilter/then/catch/then/then */
                (data) => {
                    // Verifica se la variabile è presente nell'array
                    if (data.includes(productSKU)) {
                        return { productSKU, categoryIds: 2001 };
                    }

                    return { productSKU };
                }
            )
            .catch(
                /** @namespace Pwa/Route/ProductPage/Container/ProductPageContainer/getProductRequestFilter/then/catch */
                () => false // Restituisci false in caso di errore

            );
    }

    requestProduct() {
        const { requestProduct, productSKU } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        // Chiamare getProductRequestFilter() e attendere che sia risolta prima di chiamare requestProduct
        this.getProductRequestFilter().then(
            /** @namespace Pwa/Route/ProductPage/Container/ProductPageContainer/requestProduct/getProductRequestFilter/then */
            (filter) => {
                const options = {
                    isSingleProduct: true,
                    args: { filter },
                };

                requestProduct(options);
            }
        );
    }

    render() {
        const { product: { sku }, productSKU } = this.props;

        if (!sku || sku !== productSKU) {
            return (
                <div block="ProductPage" elem="Loader">
                    <Loader isLoading />
                </div>
            );
        }

        return (
            <ProductPage
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
