import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import FakeBreadcrumbs from 'Component/FakeBreadcrumbs';
import FlixMedia from 'Component/FlixMedia';
import IfStore from 'Component/IfStore';
import InformazioniAggiuntive from 'Component/InformazioniAggiuntive';
import LinkedProductsSlider from 'Component/LinkedProductsSlider';
import MaterassoInfo from 'Component/MaterassoInfo';
import OpenChat from 'Component/OpenChat';
import ProductActions from 'Component/ProductActions';
import ProductElementsTabs from 'Component/ProductElementsTabs';
import ProductFixedBox from 'Component/ProductFixedBox';
import ProductGallery from 'Component/ProductGallery';
import ProductInfoAggiuntive from 'Component/ProductInfoAggiuntive';
import ProductTopInfo from 'Component/ProductTopInfo';
import ProductVariantsSlider from 'Component/ProductVariantsSlider';
import ProductVideo from 'Component/ProductVideo';
import Swogo from 'Component/Swogo';
import {
    CATEGORY_ID_ANGOLO_DELLE_OCCASIONI,
} from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import NoMatchHandler from 'Route/NoMatchHandler';
import { chatOpen } from 'Route/ServizioClienti/functions';
import RenderWhenVisibleComponent from 'SourceComponent/RenderWhenVisible';
import { ProductPageComponent as SourceProductPage } from 'SourceRoute/ProductPage/ProductPage.component';
import { LinkedProductType } from 'Store/LinkedProducts/LinkedProducts.type';

import './ProductPage.override.style';

/** @namespace Pwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPage {
    static propTypes = {
        storeCode: PropTypes.string.isRequired,
    };

    state = {
        expanded: false,
        needExpanded: false,
    };

    needExpanded = (flag) => {
        this.setState({ needExpanded: flag });
    };

    // Sedie, gruppi letto, tutto arredo ufficio, multiuso e scarpiere, miscelatori, lavelli, tutto complementi
    renderTemplateSedieAltro() {
        const {
            dataSource,
            device,
            product: {
                categories,
            },
        } = this.props;

        const isIlluminazione = categories && categories.find((elem) => elem.url.includes('illuminazione')) !== undefined;

        return (
            <div className="newProductPageTemplate SedieAltroProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { device.isMobile && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                { isIlluminazione && (
                    <div className="SliderVariants-Wrapper">
                    <ProductVariantsSlider
                      title={ __('Other variations') }
                    />
                    </div>
                ) }
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                { !isIlluminazione && (
                    <div className="SliderVariants-Wrapper">
                        <ProductVariantsSlider
                          title={ __('Other variations') }
                        />
                    </div>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderProductPageContent() {
        const {
            activeProduct,
            // configurableVariantIndex,
            parameters,
            getLink,
            dataSource,
            // updateConfigurableVariant,
            // productOrVariant,
            areDetailsLoaded,
            // getSelectedCustomizableOptions,
            // productOptionsData,
            // setBundlePrice,
            // selectedBundlePrice,
            // selectedBundlePriceExclTax,
            // setLinkedDownloadables,
            // setLinkedDownloadablesPrice,
            // selectedLinkPrice,
            // flashSaleProduct,
            useEmptyGallerySwitcher,
            isVariant,
            setActiveProduct,
        } = this.props;

        return (
            <>
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                />
                <ProductActions
                  getLink={ getLink }
                  product={ dataSource }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  setActiveProduct={ setActiveProduct }

                // updateConfigurableVariant={updateConfigurableVariant}
                // productOrVariant={productOrVariant}
                // configurableVariantIndex={configurableVariantIndex}
                // getSelectedCustomizableOptions={getSelectedCustomizableOptions}
                // productOptionsData={productOptionsData}
                // setBundlePrice={setBundlePrice}
                // selectedBundlePrice={selectedBundlePrice}
                // selectedBundlePriceExclTax={selectedBundlePriceExclTax}
                // setLinkedDownloadables={setLinkedDownloadables}
                // setLinkedDownloadablesPrice={setLinkedDownloadablesPrice}
                // selectedLinkPrice={selectedLinkPrice}
                // flashSaleProduct={flashSaleProduct}
                />
            </>
        );
    }

    renderProductPageContent2() {
        const {
            activeProduct,
            // configurableVariantIndex,
            parameters,
            getLink,
            dataSource,
            // updateConfigurableVariant,
            // productOrVariant,
            areDetailsLoaded,
            // getSelectedCustomizableOptions,
            // productOptionsData,
            // setBundlePrice,
            // selectedBundlePrice,
            // selectedBundlePriceExclTax,
            // setLinkedDownloadables,
            // setLinkedDownloadablesPrice,
            // selectedLinkPrice,
            // flashSaleProduct,
            useEmptyGallerySwitcher,
            isVariant,
            setActiveProduct,
        } = this.props;

        return (
            <>
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                  buttonConfigurator
                />
                <ProductActions
                  getLink={ getLink }
                  product={ dataSource }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  setActiveProduct={ setActiveProduct }

                // updateConfigurableVariant={updateConfigurableVariant}
                // productOrVariant={productOrVariant}
                // configurableVariantIndex={configurableVariantIndex}
                // getSelectedCustomizableOptions={getSelectedCustomizableOptions}
                // productOptionsData={productOptionsData}
                // setBundlePrice={setBundlePrice}
                // selectedBundlePrice={selectedBundlePrice}
                // selectedBundlePriceExclTax={selectedBundlePriceExclTax}
                // setLinkedDownloadables={setLinkedDownloadables}
                // setLinkedDownloadablesPrice={setLinkedDownloadablesPrice}
                // selectedLinkPrice={selectedLinkPrice}
                // flashSaleProduct={flashSaleProduct}
                />
            </>
        );
    }

    // renderProductPageContent2() {
    //     const {
    //         configurableVariantIndex,
    //         parameters,
    //         getLink,
    //         dataSource,
    //         updateConfigurableVariant,
    //         productOrVariant,
    //         areDetailsLoaded,
    //         getSelectedCustomizableOptions,
    //         productOptionsData,
    //         setBundlePrice,
    //         selectedBundlePrice,
    //         selectedBundlePriceExclTax,
    //         setLinkedDownloadables,
    //         setLinkedDownloadablesPrice,
    //         selectedLinkPrice,
    //         flashSaleProduct,
    //     } = this.props;

    //     return (
    //         <>

    //             <ProductGallery
    //                 product={productOrVariant}
    //                 areDetailsLoaded={areDetailsLoaded}
    //                 buttonConfigurator
    //             />
    //             <ProductActions
    //                 getLink={getLink}
    //                 updateConfigurableVariant={updateConfigurableVariant}
    //                 product={dataSource}
    //                 productOrVariant={productOrVariant}
    //                 parameters={parameters}
    //                 areDetailsLoaded={areDetailsLoaded}
    //                 configurableVariantIndex={configurableVariantIndex}
    //                 getSelectedCustomizableOptions={getSelectedCustomizableOptions}
    //                 productOptionsData={productOptionsData}
    //                 setBundlePrice={setBundlePrice}
    //                 selectedBundlePrice={selectedBundlePrice}
    //                 selectedBundlePriceExclTax={selectedBundlePriceExclTax}
    //                 setLinkedDownloadables={setLinkedDownloadables}
    //                 setLinkedDownloadablesPrice={setLinkedDownloadablesPrice}
    //                 selectedLinkPrice={selectedLinkPrice}
    //                 flashSaleProduct={flashSaleProduct}
    //             />
    //         </>
    //     );
    // }

    renderAdditionalSectionsNew() {
        const { product: { nuovo_template_option } } = this.props;

        return (
            <div block="ProductPage" elem="AdditionalWrapper">
                { this.renderSezionePuoiAcquistare() }
                { this.renderFlexMediaInPage() }
                { nuovo_template_option.label !== 'template_sedie_altro' && (
                    <div className="SliderVariants-Wrapper">
                        <ProductVariantsSlider
                          title={ __('Other variations') }
                        />
                    </div>
                ) }
                { nuovo_template_option.label !== 'template_bagni' && (
                <LinkedProductsSlider
                  linkType={ LinkedProductType.RELATED }
                  title={ __('Complete the decor') }
                />
                ) }
            </div>
        );
    }

    renderSezionePuoiAcquistare() {
        return (
            <div block="ProductPage" elem="PuoiAcquistare">
                <h3 className="ProductPage-PuoiAcquistareTitle">
                    { __('You can also buy through') }
                </h3>
                <div className="ProductPage-PuoiAcquistareContainer">
                    <div className="ProductPage-PuoiAcquistareCarousel">
                        <RenderWhenVisibleComponent>
                        <CmsBlock identifier="acquistare_tramite_puntovendita" />
                        </RenderWhenVisibleComponent>
                        <RenderWhenVisibleComponent>
                        <CmsBlock identifier="acquistare_tramite_telefono" />
                        </RenderWhenVisibleComponent>
                        <div className="CmsBlock-Wrapper CmsBlock-Wrapper_type_">
                            <div className="ProductPage-PuoiAcquistareItem">
                                <span onClick={ chatOpen }>
                                    <div
                                      className="Image Image_ratio_square Image_imageStatus_1 Image_hasSrc Image_ratio_square_chat"
                                    >
                                        <img
                                          src="https://media.mondoconv.it/media/wysiwyg/3-livello-acquistare-anche/live chat.png"
                                          alt=""
                                        />
                                    </div>
                                </span>
                                <div className="ProductPage-PuoiAcquistareSubtitle">
                                    { __('AcquistareAnche.chat') }
                                </div>
                            </div>
                        </div>
                        <RenderWhenVisibleComponent>
                        <CmsBlock identifier="acquistare_tramite_videochiamata" />
                        </RenderWhenVisibleComponent>
                        <IfStore storeCode="default">
                        <RenderWhenVisibleComponent>
                        <CmsBlock identifier="acquistare_tramite_dolcecasa" />
                        </RenderWhenVisibleComponent>
                        </IfStore>
                        <RenderWhenVisibleComponent>
                        <CmsBlock identifier="acquistare_tramite_facebook" />
                        </RenderWhenVisibleComponent>
                        <IfStore storeCode="default">
                        <RenderWhenVisibleComponent>
                        <CmsBlock identifier="acquistare_tramite_whatsapp" />
                        </RenderWhenVisibleComponent>
                        </IfStore>
                    </div>
                </div>
            </div>
        );
    }

    renderFlexMediaInPage() {
        const { dataSource: { mc_code_ean, brand_flixmedia, sku } } = this.props;

        if (mc_code_ean && brand_flixmedia) {
            return (
                <>
                    <div
                      id="flix-inpage"
                      block="ProductPage"
                      elem="FlixMedia"
                    />
                    <FlixMedia
                      ean={ mc_code_ean }
                      inpage="flix-inpage"
                      button="flix-button"
                      price=""
                      brand={ brand_flixmedia }
                      sku={ sku }
                    />
                </>
            );
        }

        return null;
    }

    renderGoBackAdo() {
        const {
            product: { categories },
        } = this.props;
        const isADO = categories && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;

        if (isADO) {
            return (
                <div className="ContentWrapper backAdoButton">
                    <a href="/angolo-delle-occasioni">
                        <i className="fa fa-chevron-left" />
                        { `${__('Angolo delle Occasioni')}` }
                    </a>
                </div>
            );
        }

        return null;
    }

    renderMosaicoBoxes() {
        const {
            product: {
                mc_box_mosaico,
                nuovo_template_option,
            },
        } = this.props;

        // Box da non mostrare sotto i 1024px
        const boxesNotShownOnMobile = [
            'mosaico_top_center',
            'mosaico_bottom_right',
        ];

        let boxes = Object.keys(mc_box_mosaico);
        // Se mi trovo su desktop (min-width: 1024px) o mi trovo sul template_camere prendo tutti i boxes, altrimenti prendo quelli che non sono inclusi in boxesNotShownOnMobile
        boxes = (window.matchMedia('(min-width: 1024px)').matches || nuovo_template_option.label === 'template_camere') ? boxes : boxes.filter((box) => !boxesNotShownOnMobile.includes(box));

        const num_mosaici = this.countMosaici(
            mc_box_mosaico.mosaico_top_left,
            mc_box_mosaico.mosaico_top_center,
            mc_box_mosaico.mosaico_top_right,
            mc_box_mosaico.mosaico_bottom_left,
            mc_box_mosaico.mosaico_bottom_center,
            mc_box_mosaico.mosaico_bottom_right
        );
        const one_row_numbers = [2, 3];

        return (
            <div
              className={ `${nuovo_template_option.label} mosaico_boxes ${one_row_numbers.includes(num_mosaici) ? 'one-row' : ''}` }
            >
                { boxes.map((box) => {
                    if (mc_box_mosaico[box].type === 'image') {
                        return (
                            (Boolean(mc_box_mosaico[box].value)) && (
                                <div className={ `${box} box_img` }>
                                    <img src={ mc_box_mosaico[box].value } alt="Mosaico" />
                                </div>
                            )
                        );
                    }

                    return (
                        (Boolean(mc_box_mosaico[box].value)) && (
                            <div className={ `${box} box_text d-flex align-items-center` }>
                                <div>
                                    { Array.isArray(this.renderMosaicoBoxText(mc_box_mosaico[box].value)) && (
                                        <h3>{ this.renderMosaicoBoxText(mc_box_mosaico[box].value)[1] }</h3>
                                    ) }
                                    { Array.isArray(this.renderMosaicoBoxText(mc_box_mosaico[box].value)) ? (
                                        parse(this.renderMosaicoBoxText(mc_box_mosaico[box].value)[2])
                                    ) : (
                                        parse(this.renderMosaicoBoxText(mc_box_mosaico[box].value))
                                    ) }
                                </div>
                            </div>
                        )
                    );
                }) }
            </div>
        );
    }

    renderMosaicoBoxText(inputText) {
        const regex = /\|\*\*(.*)\*\*\|/m;

        if (inputText.match(regex)) {
            return inputText.split(regex);
        }

        return inputText;
    }

    renderCollectionCamere() {
        const {
            product: {
                name,
                mc_collezione_camere_information,
            },
        } = this.props;

        const filteredItems = mc_collezione_camere_information.filter((item) => Boolean(item.label) && Boolean(item.image));

        return filteredItems.length > 0 && (
            <section block="Collection" elem="Camere">
                <h2>
                    { __('Collection') }
                    &nbsp;
                    { name }
                </h2>
                <div className="entity_tab_grid collezioni">
                    { filteredItems.slice(0, 4).map((item) => (
                        <a href={ item.url } className="single_collezione">
                            <img src={ item.image } alt="Immagine Collezione" />
                            <h3>{ item.label }</h3>
                        </a>
                    )) }
                </div>
            </section>
        );
    }

    renderAdditionalSectionsElettrodomestici() {
        const { expanded, needExpanded } = this.state;
        const buttonText = expanded ? __('Show less entity') : __('Show more entity');
        const style = expanded ? null : 'initial';
        const styleAux = expanded ? null : '4000px';
        const flixContainer = document.querySelector('.FlixContainer');

        return (
            <div block="ProductPage" elem="AdditionalWrapper">
                <div className="FlixContainer">
                    { this.renderFlexMediaInPage() }
                    { !expanded && needExpanded && (
                        <div id="fadeout" />
                    ) }
                </div>
                { needExpanded && (
                    <>
                        <button
                          className="mostra_di_piu"
                          onClick={ () => {
                              const newHeight = flixContainer.getBoundingClientRect().height - 2000;
                              this.showMore();
                              flixContainer.style.maxHeight = styleAux;
                              setTimeout(() => {
                                  flixContainer.style.maxHeight = style;
                              }, 500);

                              if (expanded) {
                                  window.scroll({
                                      top: window.scrollY - newHeight,
                                      behavior: 'auto',
                                  });
                              }
                          } }
                        >
                            { buttonText }
                        </button>
                        <hr />
                    </>
                ) }
                { this.waitTimeout(flixContainer) }
            </div>
        );
    }

    waitTimeout = (flixContainer) => {
        setTimeout(() => {
            this.needExpanded(flixContainer ? (flixContainer.getBoundingClientRect().height > 1999) : false);
        }, 1500);
    };

    renderAvailableCloset() {
        const {
            product: {
                mc_product_variants, mc_dimension_height, mc_dimension_width,
                mc_dimension_depth, mc_inline_description,
                placeholder_text,
                price_range: {
                    maximum_price: { final_price },
                },
            },
        } = this.props;

        const optionList = [];
        // eslint-disable-next-line array-callback-return
        Object.values(mc_product_variants).sort((a, b) => parseInt(a.varianttag_sorting === null ? 0 : a.varianttag_sorting) - parseInt(b.varianttag_sorting === null ? 0 : b.varianttag_sorting)).map((variant) => {
            (
                optionList.push(
                    <li>
                        <a href={ variant.url } className="closet-anchor">
                            <span className="closet_description">{ variant.mc_inline_description }</span>
                            <span className="closet_dimension">
                                { variant.mc_dimension_width }
                                x
                                { variant.mc_dimension_depth }
                                x
                                { variant.mc_dimension_height }
                                &nbsp;cm
                            </span>
                            <span className="closet_price">
                                { variant.price_range.maximum_price.final_price.value }
                                €
                            </span>
                            <span className="dropdown-icon">
                                &nbsp;
                            </span>
                        </a>
                    </li>
                )
            );
        });

        return (
            <div block="AvailableCloset" elem="Container">
                <div className="Select-Closet">
                    <p>{ __('Closet available') }</p>
                    <div className="ul-closet-container">
                        <ul className="closet_variants_dropdown">
                            <li id="selectedCloset" className="selectedItem">
                                <button className="closet-anchor">
                                    <span className="closet_description">{ mc_inline_description }</span>
                                    <span className="closet_dimension">
                                        { mc_dimension_width }
                                        x
                                        { mc_dimension_depth }
                                        x
                                        { mc_dimension_height }
                                        &nbsp;cm
                                    </span>
                                    <span className="closet_price">
                                        { final_price.value }
                                        €
                                    </span>
                                    <span className="dropdown-icon-hidden">
                                        &nbsp;
                                    </span>
                                </button>
                            </li>
                            { optionList }
                        </ul>
                    </div>
                </div>
                <div className="Closet-Placeholder">
                    { this.renderPlaceholder() }
                    <p>{ placeholder_text }</p>
                </div>
            </div>
        );
    }

    renderPlaceholder() {
        const {
            product: {
                media_gallery_entries,
            },
        } = this.props;

        const placeholderVideo = media_gallery_entries.filter((media) => media.types.includes('placeholder_video'));

        if (placeholderVideo.length > 0) {
            return (
                <img src={ placeholderVideo[0].base.url } alt="Placeholder Video" />
            );
        }

        return null;
    }

    renderAdditionalSectionsOld() {
        const { dataSource } = this.props;

        return (
            <>
                { this.renderSezionePuoiAcquistare() }
                { this.renderFlexMediaInPage() }
                <InformazioniAggiuntive product={ dataSource } />
                <div block="ProductPage" elem="AdditionalWrapper">
                    <ProductVariantsSlider
                      title={ __('Other variations') }
                    />
                    <LinkedProductsSlider
                      linkType={ LinkedProductType.RELATED }
                      title={ __('Complete the decor') }
                    />
                </div>
            </>

        );
    }

    countMosaici(...mosaici) {
        return mosaici.filter((elem) => elem.value !== '').length;
    }

    templatePage = () => {
        const {
            product: {
                nuovo_template_option,
            },
        } = this.props;

        switch (nuovo_template_option.label) {
        case 'template_cucine_componibili':
            return this.renderTemplateCucine();
        case 'template_cucine_componibiliV2':
            return this.renderTemplateCucineV2();
        case 'template_cucine_bloccate':
            return this.renderTemplateCucineBloccate();
        case 'template_divani':
        case 'template_divani_2':
            return this.renderTemplateDivani();
        case 'template_letti':
            return this.renderTemplateLetti();
        case 'template_camere':
            return this.renderTemplateCamere();
        case 'template_camerette':
            return this.renderTemplateCamerette();
        case 'template_bagni':
            return this.renderTemplateBagni();
        case 'template_soggiorni_madie':
            return this.renderTemplateSoggiorniMadie();
        case 'template_sedie_altro':
            return this.renderTemplateSedieAltro();
        case 'template_camerette_bloccate':
            return this.renderTemplateCameretteBloccate();
        case 'template_tavoli':
            return this.renderTemplateTavoli();
        case 'template_materassi':
            return this.renderTemplateMaterassi();
        case 'template_reti':
            return this.renderTemplateReti();
        case 'template_elettrodomestici':
            return this.renderTemplateElettrodomestici();
        case 'template_armadi':
        case 'template_armadi2':
            return this.renderTemplateArmadi();
        default:
            return this.renderOldTemplate();
        }
    };

    renderOldTemplate() {
        return (
            <div className="OldTemplate">
                <FakeBreadcrumbs productName={ this.props.dataSource.name } />
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                { this.renderAdditionalSectionsOld() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
            </div>
        );
    }

    renderTemplateArmadi() {
        const {
            dataSource,
            product: { nuovo_template_option },
        } = this.props;

        const template = nuovo_template_option.label === 'template_armadi' ? 'ArmadiProductPageTemplate' : 'ArmadiProductPageTemplate2';

        return (
            <div className={ `newProductPageTemplate ${template}` }>
                { window.matchMedia('(max-width: 767px)').matches && (
                    <FakeBreadcrumbs productName={ this.props.dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                { nuovo_template_option.label === 'template_armadi2' && (
                    <ContentWrapper>
                        { this.renderAvailableCloset() }
                    </ContentWrapper>
                ) }
                <ContentWrapper>
                    { this.renderCollectionCamere() }
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { nuovo_template_option.label !== 'template_armadi2' && this.renderAdditionalSectionsNew() }
                { nuovo_template_option.label === 'template_armadi2' && this.renderSezionePuoiAcquistare() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderAdditionalSectionsNew2() {
        const { product: { nuovo_template_option } } = this.props;

        return (
            <div block="ProductPage" elem="AdditionalWrapper">
                { this.renderSezionePuoiAcquistare() }
                { this.renderFlexMediaInPage() }
                { nuovo_template_option.label !== 'template_bagni' && (
                <LinkedProductsSlider
                  linkType="related"
                  title={ __('Complete the decor') }
                />
                ) }
            </div>
        );
    }

    renderTemplateElettrodomestici() {
        const {
            dataSource,
        } = this.props;

        return (
            <div className="newProductPageTemplate ElettrodomesticiProductPageTemplate">
                { window.matchMedia('(max-width: 767px)').matches && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductFlix', elem: 'Wrapper' } }
                >
                    { this.renderAdditionalSectionsElettrodomestici() }
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderSezionePuoiAcquistare() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'AdditionalWrapper' } }
                >
                    <ProductVariantsSlider
                      title={ __('Other variations') }
                    />
                    <LinkedProductsSlider
                      linkType={ LinkedProductType.RELATED }
                      title={ __('Complete the decor') }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateReti() {
        const {
            dataSource,
        } = this.props;

        return (
            <div className="newProductPageTemplate RetiProductPageTemplate">
                { window.matchMedia('(max-width: 767px)').matches && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateMaterassi() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate MaterassiProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ContentWrapper>
                    <MaterassoInfo product={ dataSource } />
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateTavoli() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate TavoliProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { device.isMobile && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateCameretteBloccate() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate CameretteBloccateProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ this.props.dataSource.name } />
                ) }
                { device.isMobile && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } } label={ __('Main product details') }>
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive product={ dataSource } />
                </ContentWrapper>
                { /* Video a sx e descrizione a dx */ }
                <ContentWrapper wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }>
                    <ProductVideo />
                </ContentWrapper>
                { /* Mosaioo */ }
                <ContentWrapper>
                    { this.renderMosaicoBoxes() }
                </ContentWrapper>
                { /* Collezione non prevista per le camerette bloccate */ }
                { /* <ContentWrapper> */ }
                { /*    { this.renderCollectionCamere() } */ }
                { /* </ContentWrapper> */ }
                { /* Misure, Finiture e Colori, Complementi */ }
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateSoggiorniMadie() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate SoggiorniMadieProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ this.props.dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ContentWrapper>
                    { this.renderMosaicoBoxes() }
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateBagni() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate BagniProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ this.props.dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <LinkedProductsSlider
                  linkType="related"
                  title={ __('Complete the bathroom') }
                />
                <ContentWrapper>
                    { this.renderMosaicoBoxes() }
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateCamerette() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate CameretteProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { device.isMobile && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'ComposizioniTipo' } }
                >
                    <CmsBlock
                      identifier="composizioni-camerette"
                    />
                </ContentWrapper>
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateCamere() {
        const {
            dataSource,
        } = this.props;

        return (
            <div className="newProductPageTemplate CamereProductPageTemplate">
                { window.matchMedia('(max-width: 767px)').matches && (
                    <FakeBreadcrumbs productName={ this.props.dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ContentWrapper>
                    { this.renderMosaicoBoxes() }
                </ContentWrapper>
                <ContentWrapper>
                    { this.renderCollectionCamere() }
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateLetti() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate LettiProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { device.isMobile && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'SliderVariants', elem: 'Wrapper' } }
                >
                    <ProductVariantsSlider
                      title={ __('Other variations') }
                    />
                    <div className="separator" />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew2() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateDivani() {
        const {
            dataSource,
            device,
            product: { nuovo_template_option },
        } = this.props;

        return (
            <div className="newProductPageTemplate DivaniProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ this.props.dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                { nuovo_template_option.label !== 'template_divani_2' && (
                    <>
                        <ContentWrapper
                          wrapperMix={ { block: 'SliderVariants', elem: 'Wrapper' } }
                        >
                            <ProductVariantsSlider
                              title={ __('Other variations') }
                            />
                            <div className="separator" />
                        </ContentWrapper>
                        <ContentWrapper
                          wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                        >
                            <ProductVideo />
                        </ContentWrapper>
                        <ContentWrapper>
                            { this.renderMosaicoBoxes() }
                        </ContentWrapper>
                    </>
                ) }
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew2() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateCucineBloccate() {
        const {
            dataSource,
            device,
        } = this.props;

        return (
            <div className="newProductPageTemplate CucineBloccateProductPageTemplate">
                { device.isMobile && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent2() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ContentWrapper>
                    { this.renderMosaicoBoxes() }
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                { this.renderAdditionalSectionsNew() }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateCucineV2() {
        const {
            dataSource,
        } = this.props;

        return (
            <div className="newProductPageTemplate CucineV2ProductPageTemplate">
                { window.matchMedia('(max-width: 767px)').matches && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                { window.matchMedia('(max-width: 767px)').matches && (
                    <ContentWrapper>
                        <ProductTopInfo />
                    </ContentWrapper>
                ) }
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                <IfStore storeCode="default">
                    <ContentWrapper>
                        <div className="composizione_tipo">
                            <CmsBlock identifier="composizioni-cucine" />
                        </div>
                    </ContentWrapper>
                </IfStore>
                <ContentWrapper>
                    { this.renderAdditionalSectionsNew() }
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    renderTemplateCucine() {
        const {
            dataSource,
        } = this.props;

        return (
            <div className="newProductPageTemplate CucineProductPageTemplate">
                { window.matchMedia('(max-width: 767px)').matches && (
                    <FakeBreadcrumbs productName={ dataSource.name } />
                ) }
                <ContentWrapper>
                    <ProductTopInfo />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { this.renderProductPageContent() }
                </ContentWrapper>
                <ContentWrapper>
                    <ProductInfoAggiuntive
                      product={ dataSource }
                    />
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductVideo', elem: 'Wrapper' } }
                >
                    <ProductVideo />
                </ContentWrapper>
                <ProductElementsTabs product={ dataSource } />
                <ContentWrapper>
                    <div className="composizione_tipo">
                        <CmsBlock identifier="composizioni-cucine" />
                    </div>
                </ContentWrapper>
                <ContentWrapper>
                    { this.renderAdditionalSectionsNew() }
                </ContentWrapper>
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Swogo' } }
                  label={ __('Swogo') }
                >
                    <Swogo bundleId={ 0 } />
                </ContentWrapper>
                <OpenChat />
                <ProductFixedBox />
            </div>
        );
    }

    render() {
        const {
            product: { categories },
        } = this.props;
        const isADO = categories && categories?.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;
        const clannCose = isADO ? 'adoMain' : '';

        return (
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  className={ clannCose }
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    { this.renderGoBackAdo() }
                    { this.templatePage() }
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPageComponent;
