import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    GARANZIA_5,
    GARANZIA_10,
    GARANZIA_15,
} from 'Component/ProductActions/ProductActions.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { ProductType } from 'Type/ProductList.type';

import ProductInfoAggiuntive from './ProductInfoAggiuntive.component';

/** @namespace Pwa/Component/ProductInfoAggiuntive/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/ProductInfoAggiuntive/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // ...sourceMapDispatchToProps(dispatch),
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
});

/** @namespace Pwa/Component/ProductInfoAggiuntive/Container */
export class ProductInfoAggiuntiveContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
    };

    state = {
        configData: null,
    };

    async componentDidMount() {
        const configData = await this.getapiUrl();
        this.setState({ configData });
    }

    async getapiUrl() {
        try {
            // Ottieni i dati dall'API
            const response = await fetch('/rest/V1/configurazioneRate/get/');
            const apiData = await response.json();

            return apiData;
        } catch (error) {
            return 'errore';
            // Gestisci l'errore secondo le tue esigenze
        }
    }

    containerProps = () => ({
        anniGaranzia: this.getAnniGaranzia(),
    });

    getAnniGaranzia() {
        const { product: { gar5anni, gar10anni, gar15anni } } = this.props;

        if (gar5anni > 0) {
            return GARANZIA_5;
        }

        if (gar10anni > 0) {
            return GARANZIA_10;
        }

        if (gar15anni > 0) {
            return GARANZIA_15;
        }

        return 0;
    }

    render() {
        const { configData } = this.state;

        return (
            <ProductInfoAggiuntive
              { ...this.props }
              configData={ configData }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoAggiuntiveContainer);
